<template>
  <div>
    <div v-if="errorLoadingClass">
      <v-container fluid>
        <v-row>
          <v-col>
            <h4>Class could not be loaded!</h4>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else-if="!classLoading">
      <v-container
        fluid
        class="pt-0 pb-0"
      >
        <app-header
          :title="sectionName"
          :is-secondary="true"
          :section-editable="sectionEditable"
        >
          <template #detail>
            <v-chip
              v-if="!sectionEditable"
              class="section-chip-container"
            >
              <span class="section-chip-text font-weight-700">
                This class is created by Horizon, and automatically enrolls students based on grade level and accommodations status
              </span>
            </v-chip>
          </template>
        </app-header>
      </v-container>
      <app-tabs-secondary
        :tabs="tabs"
        @onTabChange="onLoadCategory(...arguments)"
      />

      <div
        v-if="tabSelected=== 0"
        class="mt-1"
      >
        <student-list
          :school-id="id"
          :section-id="sectionId"
          :section-editable="sectionEditable"
          @onMasquerade="onMasquerade"
        />
      </div>
      <div v-else-if="tabSelected === 1">
        <assessment-schedules
          :id="id"
          :class-specific="true"
          :section-id="sectionId"
        />
      </div>
      <div v-else>
        <class-settings
          :section-id="sectionId"
          :school-id="id"
          :section-editable="sectionEditable"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapGetters} = createNamespacedHelpers('user')
const {mapActions: mapPageActions, mapGetters: mapPageGetters} = createNamespacedHelpers('pages')
import AppHeader from "@/components/shared/AppHeader.vue";
import AppTabsSecondary from "@/components/shared/AppTabsSecondary.vue";
import ClassSettings from "@/components/shared/ClassSettings.vue";
import AssessmentSchedules from "@/components/shared/AssessmentSchedules.vue";
import StudentList from "@/components/shared/StudentList.vue";
import {AdminSectionApi, StaffSectionApi} from "@/api";

export default {
  name: "AdminClass",
  components: {StudentList, AssessmentSchedules, ClassSettings, AppTabsSecondary, AppHeader},
  props: {
    sectionId: {
      default: '',
      type: [Number, String],
    },
    id: {
      type: [String, Number],
      default: null
    },
  },

  data() {
    return {
      tabSelected: 0,
      sectionName: '',
      errorLoadingClass: false,
      classLoading: true,
      sectionEditable: true,
      classLoaded: false,
      tabs: [
        {
          tabText: 'Students',
          text: 'Students'
        },
        {
          tabText: 'Assessments',
          text: 'Active Assessments'
        },
        {
          tabText: 'Settings',
          text: 'Class Settings'
        },
      ],
    }
  },

  mounted() {
    if (this.masqPageState() && this.masqPageState().page === this.$options.name) {
        let savedPageState = this.masqPageState().data
        this.sectionName = savedPageState.sectionName
        this.sectionEditable = savedPageState.sectionEditable
        this.tabSelected = savedPageState.tabSelected
        this.classLoading = false
        this.resetMasqueradeData()
    } else {
      this.getSectionName()
    }
  },

  methods: {
    ...mapGetters(['userRole']),
    ...mapPageActions(['savePageBeforeMasquerade', 'resetMasqueradeData']),
    ...mapPageGetters(['masqPageState']),

    onLoadCategory(tab) {
      this.tabSelected = tab
    },

    async getSectionName() {
      try {
        // TODO: Prevent calling these functions when they are already available
        this.classLoading = true
        let response = {}
        if (this.userRole() === 'Horizon Admin') {
          response = await AdminSectionApi.getSingleSection(this.sectionId)
        } else {
          response = await StaffSectionApi.get(this.sectionId)
        }
        const section = response.section

        this.sectionName = section.displayName
        this.sectionEditable = section.editable
        this.errorLoadingClass = false
      } catch {
        this.errorLoadingClass = true
        this.addNotification('error', 'Failed to load class name')
      } finally {
        this.classLoading = false
      }
    },

    onMasquerade() {
      this.savePageBeforeMasquerade({
          page: this.$options.name,
          path: this.$route.path,
          data: { 
            sectionName: this.sectionName,
            sectionEditable: this.sectionEditable,
            tabSelected: this.tabSelected,
          }
        })
    }
  },
}
</script>

<style scoped>
.section-chip-container {
  height: auto;
  max-width: 390px;
  margin-left: 10px;
  padding: 8px 20px;
  background-color: #ffb5af !important;
}

.section-chip-text {
  font-size: 12px;
  line-height: 17px;
  white-space: normal;
  color: black;
}
</style>