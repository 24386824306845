<template>
  <div class="">
    <v-app-bar
      v-if="!isMediumBreakpoint"
      app
      dark
    >
      <v-img
        contain
        :src="require('../assets/img/Horizon-Education-Logo-White-text-for-dark-backgrounds-p-500.png')"
        max-width="160"
        max-height="72"
        alt="icon-add"
        class="cursor-pointer"
        @click="toHome()"
      />

      <v-icon
        size="55"
        class="mr-2"
        @click="navDrawer = !navDrawer"
      >
        mdi-menu
      </v-icon>
    </v-app-bar>
    <v-navigation-drawer
      v-model="navDrawer"
      app
      width="220px"
      :right="!isMediumBreakpoint"
      dark
      class="navigation-main"
      :permanent="isMediumBreakpoint"
    >
      <div class="d-flex flex-column align-content-space-between fill-height">
        <div
          v-if="!isMediumBreakpoint"
          class="d-flex justify-end mb-4 pa-2"
        >
          <v-icon
            size="25"
            class="mr-1"
            @click="navDrawer = !navDrawer"
          >
            mdi-close
          </v-icon>
        </div>
        <v-list>
          <v-img
            v-if="isMediumBreakpoint"
            height="82"
            width="183.5"
            :src="require('@/assets/img/Horizon-Education-Logo-White-text-for-dark-backgrounds-p-500.png')"
            class="img-logo cursor-pointer"
            tabindex="1"
            @click="toHome()"
            @keydown.enter="toHome()"
          />
          <v-list-item
            v-for="item in navigation"
            :key="item.title"
            link
            :to="item.route"
          >
            <v-img
              class="mr-3"
              contain
              :src="require(`../assets/icons/${item.icon}`)"
              max-width="18"
              alt="icons-nav"
              max-height="18"
            />
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="isTeacherAdmin()"
            link
            :to="toSchoolDashboard()"
          >
            <v-img
              class="mr-3"
              contain
              :src="require(`../assets/icons/settings2.png`)"
              max-width="18"
              max-height="18"
              alt="icon-settings"
            />

            <v-list-item-content>
              <v-list-item-title>School Admin</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-spacer class="d-none d-md-block" />

        <v-list>
          <v-list-item
            v-if="districtAdminSchoolAccess()"
            link
            @click="backToSchools()"
          >
            <v-icon
              class="mr-3"
              size="18"
              color="#90adc2"
            >
              mdi-arrow-up-thin-circle-outline
            </v-icon>

            <v-list-item-content>
              <v-list-item-title>Back to Schools</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="isMasq()"
            link
            @click="onUnmasquerade()"
          >
            <v-icon
              class="mr-3"
              size="18"
              color="#90adc2"
            >
              mdi-arrow-up-thin-circle-outline
            </v-icon>

            <v-list-item-content>
              <v-list-item-title>Back to Admin</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="hasUserSettings()"
            link
            :to="settingsPage()"
          >
            <v-img
              class="mr-3"
              contain
              :src="require(`../assets/icons/settings2.png`)"
              max-width="18"
              max-height="18"
              alt="icon-settings"
            />

            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="logout()"
          >
            <v-img
              class="mr-3"
              contain
              :src="require(`../assets/icons/logout2.png`)"
              max-width="18"
              max-height="18"
              alt="icon-logout"
            />

            <v-list-item-content>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState: mapAppConfigState} = createNamespacedHelpers('app_config')
const {mapActions: mapUserActions, mapGetters: mapGettersUser} = createNamespacedHelpers('user')
const {mapGetters: mapGettersConfig} = createNamespacedHelpers('app_config')
const {mapActions: mapCachedActions, mapGetters: mapPageGetters} = createNamespacedHelpers('pages')

import {StaffMultipleSchoolApi} from "@/api"

export default {
  name: "AppNavigation",
  data() {
    return {
      navDrawer: false
    }
  },

  computed: {
    ...mapAppConfigState({
      navigation: app_config => app_config.navigation,
      isMobile: app_config => app_config.isMobile,
    }),

    isMediumBreakpoint() {
      return this.$vuetify.breakpoint.mdAndUp
    },
  },

  methods: {
    ...mapUserActions(['createUserSession', 'loadUserSession', 'destroyUserSession', 'updateDistrictAdminState']),
    ...mapGettersConfig(["dashboard"]),
    ...mapGettersUser(['user', 'userSchool', "userRole", "userMasqId", "userFullName", "userMasqIdent", "districtAdminMasq", "districtAdminSettings"]),
    ...mapCachedActions(['resetCache']),
    ...mapPageGetters(['masqPageState']),

    toSchoolDashboard() {
      const school = this.userSchool()
      return {path: `/staff/school-dashboard/${school.id}`}
    },

    hasUserSettings() {
      if (this.districtAdminMasq()) {
        return false
      }

      return ['Horizon Admin', 'Teacher Admin', 'Teacher'].includes(this.userRole())
    },

    settingsPage() {
      switch (this.userRole()) {
        case 'Horizon Admin':
          return '/admin/settings'
        case 'Teacher Admin':
          return '/staff/settings'
        case 'Teacher':
          return '/staff/settings'
      }
    },

    isTeacherAdmin() {
      return this.userRole() === 'Teacher Admin' || (this.userRole() === "District Admin" && Object.prototype.hasOwnProperty.call(this.user(), "school"))
    },

    isMasq() {
      return this.userMasqIdent()
    },

    districtAdminSchoolAccess() {
      return this.userRole() === "District Admin" && Object.prototype.hasOwnProperty.call(this.user(), "school")
    },

    async onUnmasquerade() {
      try {
        let payload = {
          password: this.userFullName(),
          masqid: this.userMasqId()
        }
        await this.createUserSession({login: payload})

        // check if we have saved masq page state
        // if we do, push the page,
        // if we don't, push the dashboard
        if (this.masqPageState()) {
          await this.$router.push({path: this.masqPageState().path})
        } else {
          await this.$router.push(this.dashboard())
        }

        this.addNotification('success', 'You have logged back in to your account.')
      } catch {
        this.addNotification('error', 'Something went wrong. Please try again later.')
      }
    },

    async backToSchools() {
      await StaffMultipleSchoolApi.unlink()
      await this.loadUserSession()
      await this.$router.push(this.dashboard())
    },

    async logout() {
      if (this.userRole() === "District Admin") {
        await StaffMultipleSchoolApi.unlink()
      }
      await this.destroyUserSession()
      await this.resetCache()
      this.$router.go('/')
    },

    toHome() {
      if (!['Schools', 'StaffHome', 'StudentDashboard', 'DistrictSchools', 'ParentDashboard'].includes(this.$route.name)) {
        this.$router.push(this.dashboard())
      }
    },


  },
}
</script>

<style lang="scss" scoped>

.navigation-main {
  padding: 15px 0 10px 5px;

  .v-list {
    padding: 0;
  }

  .v-list-item {
    height: 52px;
    padding: 12px 5px 15px 20px;
    margin: 0 0 5px 3px;
    align-items: center;

    &__title {
      line-height: 24px;
    }

    .v-list-item__title {
      margin-top: 1px;
    }

  }

  .v-list-item__icon {
    align-self: center;
  }

  .v-list-item__content {
    padding: 0;
  }

  .v-list-item--active, .v-list-item--active:before, .v-list-item:hover, .v-list-item:hover::before {
    background-color: rgba(82, 108, 126, 0.66) !important;
  }

  .v-list-item--active::before {
    opacity: 0;
  }

}

.navigation-main .v-list-item, .navigation-main .v-list-item::before, .navigation-main .v-list-item > .v-ripple__container {
  border-radius: 20px 0 0 20px !important;
}

.img-logo {
  margin-bottom: 10px;
}

.support-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;

  .text-header {
    margin-bottom: 4px;
    margin-top: -8px;
  }

  .btn-support-container {
    padding: 0 17px;
    width: 100%;
  }
}

</style>
