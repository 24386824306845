<template>
  <div>
    <app-tabs-secondary
      :tabs="tabs"
      :active-tab="tabSelected"
      @onTabChange="onLoadCategory(...arguments)"
    />
    <v-container fluid>
      <v-row
        align="center"
        dense
        class="title-min-58"
      >
        <v-col
          cols="12"
          sm="auto"
        >
          <h3>
            {{ tabTypePlural }}
          </h3>
        </v-col>
        <v-spacer />
        <v-col
          v-if="tabSelected === 0"
          cols="12"
          sm="auto"
        >
          <v-btn
            dark
            class="btn-default btn-default__primary"
            :loading="tableLoading"
            block
            @click="onCreateClass()"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('@/assets/icons/add-1.png')"
              alt="icon-add"
              max-width="19"
              max-height="19"
            />
            Create Class
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div>
            <v-row
              dense
              class="table-header-extension"
            >
              <v-col
                cols="12"
                sm="6"
                md="4"
                xl="3"
                class="d-flex align-center"
              >
                <v-text-field
                  v-model="tableSearch"
                  placeholder="Search"
                  prepend-inner-icon="mdi-magnify"
                  class="input-default input-default__table"
                  clearable
                  hide-details
                  solo
                  flat
                />
              </v-col>
              <v-spacer />
              <v-col
                v-if="tabSelected === 0"
                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onHideClasses()"
                >
                  Hide Classes
                </v-btn>
              </v-col>
              <v-col
                v-if="tabSelected === 2"
                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onUnhideClasses()"
                >
                  Unhide Classes
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onDeleteDialog()"
                >
                  Delete Classes
                </v-btn>
              </v-col>
              <v-col
                v-if="noSelectedUsers"
                cols="12"
              >
                <h6 class="color-danger text-right">
                  Please select at least one class!
                </h6>
              </v-col>
            </v-row>

            <v-data-table
              v-model="selectedClasses"
              :height="getTableHeight()"
              show-select
              item-key="id"
              :server-items-length="totalUsers"
              :options.sync="tableOptions"
              :headers="headers"
              :headers-length="headers.length + 1"
              :items="classesList"
              :loading="tableLoading ? '#3d5d74' : false"
              class="table-default table-default__select table-radius-top-none table-default__header-fixed"
              fixed-header
              :footer-props="{
                showCurrentPage: true,
                itemsPerPageOptions:[itemsPerPage],
                disableItemsPerPage: true,
              }"
            >
              <template #[`footer.page-text`]="items">
                <span class="font-weight-700">{{ items.pageStart }}</span> to
                <span class="font-weight-700">{{ items.pageStop }}</span> of
                <span class="font-weight-700">{{ items.itemsLength }}</span>
              </template>

              <template
                v-for="header in headers"
                #[`header.${header.value}`]
              >
                <span
                  :key="header.text"
                  :aria-label="`Header: ${header.text}`"
                  tabindex="0"
                >
                  {{ header.text }}
                </span>
              </template>

              <template
                v-if="!isMobile"
                #[`header.term`]="{ header }"
              >
                <div class="d-flex align-center justify-space-between">
                  <span
                    class="mr-3"
                    :aria-label="`Header: ${header.text}`"
                    tabindex="0"
                  >
                    {{ header.text }}
                  </span>
                  <app-table-filter
                    :filter-options="termFilterOptions"
                    :column="header?.value"
                    :loading="tableLoading"
                    @onFilterChange="onFilterChange"
                  />
                </div>
              </template>

              <template
                v-if="!isMobile"
                #[`item.studentCount`]="{ item }"
              >
                <td>
                  <span class="font-weight-700 color-orange">{{ item.studentCount }}</span> Students
                </td>
              </template>
              <template
                v-if="!isMobile"
                #[`item.primaryTeachers`]="{ item }"
              >
                <td>
                  <span
                    v-for="(teacher, index) in item.primaryTeachers"
                    :key="teacher.id"
                  >
                    {{ `${teacher.firstName} ${teacher.lastName}` }}{{ index !== item.primaryTeachers.length - 1 ? ',' : '' }}
                  </span>
                </td>
              </template>

              <template
                v-if="!isMobile"
                #[`item.actions`]="{ item }"
              >
                <td>
                  <v-row dense>
                    <v-col class="d-flex justify-end">
                      <v-btn
                        class="btn-table btn-table__text"
                        outlined
                        @click="onClassSettings(item.id)"
                      >
                        Class Settings
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </template>

              <template
                v-if="!isMobile"
                #[`item.term`]="{ item }"
              >
                <td>
                  <span>{{ item.term === null ? '' : item.term.name }}</span>
                </td>
              </template>

              <template
                v-if="!isMobile"
                #[`item.createdAt`]="{ item }"
              >
                <td>
                  <span>{{ formatDateTable(item.createdAt) }}</span>
                </td>
              </template>

              <template
                v-if="isMobile"
                #item="{ item, isSelected, select }"
              >
                <tr>
                  <td>
                    <ul class="flex-content">
                      <li
                        class="flex-item"
                        data-label="Selected"
                      >
                        <v-simple-checkbox
                          :value="isSelected"
                          @click="select(!isSelected)"
                        />
                      </li>
                      <li
                        class="flex-item"
                        data-label="Class Name"
                      >
                        {{ item.displayName }}
                      </li>

                      <li
                        class="flex-item"
                        data-label="Primary Teacher"
                      >
                        <span
                          v-for="(teacher, index) in item.primaryTeachers"
                          :key="teacher.id"
                        >
                          {{ `${teacher.firstName} ${teacher.lastName}` }}{{ index !== item.primaryTeachers.length - 1 ? ',' : '' }}
                        </span>
                      </li>

                      <li
                        class="flex-item"
                        data-label="Term"
                      >
                        <span>{{ item.term === null ? '' : item.term.name }}</span>
                      </li>

                      <li
                        v-if="tabSelected === 0"
                        class="flex-item"
                        data-label="Date Created"
                      >
                        {{ formatDateTable(item.createdAt) }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="Students Enrolled"
                      >
                        <span class="font-weight-700 color-orange">{{ item.studentCount }}</span> Students
                      </li>
                      <v-col
                        cols="12"
                        class="d-flex"
                      >
                        <v-btn
                          class="btn-table"
                          outlined
                          @click="onClassSettings(item.id)"
                        >
                          Class Settings
                        </v-btn>
                      </v-col>
                    </ul>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <dialog-create-class
      :active="dialogCreateClass"
      :school-id="id"
      @closeDialog="onCloseCreateClassDialog()"
      @closeDialogUpdate="onCloseCreateClassDialog(true)"
    />

    <dialog-action
      :active="dialogHideClass"
      :loading="actionLoader"
      body-text-prop="Do you want to hide the selected classes?"
      @closeDialog="onCloseDialogHideClasses()"
      @confirmDialog="onConfirmStatusChange(false)"
    />

    <dialog-action
      :active="dialogUnhideClass"
      body-text-prop="Do you want to unhide the selected classes?"
      @closeDialog="onCloseDialogUnhideClasses()"
      @confirmDialog="onConfirmStatusChange(true)"
    />

    <dialog-delete-confirmation
      :active="dialogDeleteConfirmation"
      :deletion-loader-prop="deletionLoader"
      @closeDialog="onoCloseDialogDeleteConfirmation()"
      @confirmDeletion="onRemoveMultipleClasses()"
    />
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

import moment from 'moment';

const {mapState} = createNamespacedHelpers('app_config')
const {mapGetters} = createNamespacedHelpers('user')
import AppTabsSecondary from "@/components/shared/AppTabsSecondary.vue";
import DialogAction from "@/components/shared/DialogAction.vue";
import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue";
import DialogCreateClass from "@/components/shared/DialogCreateClass.vue";
import debounce from 'lodash/debounce'
import {AdminSchoolApi, AdminSectionApi, StaffSchoolApi, StaffSectionApi} from "@/api";
import AppTableFilter from "@/components/shared/AppTableFilter.vue";
import cachedMixin from '@/mixins/cachedMixin'


export default {
  name: "AdminClasses",
  components: {AppTableFilter, DialogCreateClass, DialogDeleteConfirmation, AppTabsSecondary, DialogAction},

  mixins: [
    cachedMixin
  ],

  props: {
    id: {
      type: [Number, String],
      default: null
    },
    school: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      pageLoadedOnce: false,
      onTableSearch: false,
      tableLoading: true,
      actionLoader: false,
      dialogDeleteConfirmation: false,
      dialogCreateClass: false,
      deletionLoader: false,
      dialogHideClass: false,
      dialogUnhideClass: false,
      tabSelected: 0,
      selectedClasses: [],
      noSelectedUsers: false,
      tableSearch: '',
      classesList: [],
      tableOptions: {},
      itemsPerPage: 100,
      totalUsers: 0,
      tableHeight: 'calc(90vh - 544px)',
      termFilterOptions: [],
      tableActiveFilters: {},
      tabs: [
        {
          tabText: 'Active Classes',
          text: 'Active Classes'
        },
        {
          tabText: 'Archived Classes',
          text: 'Archived Classes'
        },
        {
          tabText: 'Hidden Classes',
          text: 'Hidden Classes'
        },
      ],
      headers: [
        {
          text: 'Class Name',
          value: 'displayName',
          align: 'left',
          sortable: true,
          sortBy: 'displayName',
        },
        {
          text: 'Term',
          value: 'term',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Primary Teacher',
          value: 'primaryTeachers',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Date Created',
          value: 'createdAt',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Students Enrolled',
          value: 'studentCount',
          align: 'left',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 100
        }
      ],
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),

    tabTypePlural() {
      return this.tabs[this.tabSelected].tabText
    },

    tabTypeSingular() {
      return this.tabs[this.tabSelected].text
    }
  },

  watch: {
    tableSearch: {
      handler: debounce(function () {
        // Set page to 1 before searching
        this.resetPage()
        this.onTableSearch = true
        this.getClasses()
      }, 500),
    },

    tableOptions: {
      handler() {
        if (!this.onTableSearch && this.pageLoadedOnce) {
          this.getClasses()
        }
      },
      deep: true,
    },
  },


  beforeRouteLeave(to, from, next) {
    this.savePageData({ 
      page: this.$options.name, 
      data: { 
        schoolId: this.id,
        totalUsers: this.totalUsers,
        tableSearch: this.tableSearch,
        tabSelected: this.tabSelected,
        tableOptions: this.tableOptions
      }
    })
    next()
  },

  beforeRouteEnter(to, from, next) {
    if (from.name != null && (from.name.includes("SchoolDashboard/") || from.name.includes("AdminDashboard/")) && from.params.id == to.params.id || (window.event && window.event.type == "popstate")) {
      next( vm => {
        vm.shouldTryCachedData = true
      })      
    }
    else {
      next()
    }
  },

  mounted() {

    if (!this.cachedDataLoaded && this.shouldTryCachedData && this.hasCachedData) {
      if (this.cachedData.schoolId != this.id) {
        return
      }
      this.tableLoading = true
      this.totalUsers = this.cachedData.totalUsers
      this.tableSearch = this.cachedData.tableSearch
      this.tabSelected = this.cachedData.tabSelected
      this.tableOptions = this.cachedData.tableOptions
      this.tableLoading = false
      this.cachedDataLoaded = true
    } else {
      this.onGetSchoolTerms()
    }
  },

  methods: {
    ...mapGetters(['userRole']),

    onLoadCategory(tab) {
      this.tabSelected = tab
      this.tableOptions.page = 1
      this.getClasses()
      this.resetSelectedClasses()
    },

    async onGetSchoolTerms() {
      try {
        const api = this.userRole() === 'Horizon Admin' ? AdminSchoolApi : StaffSchoolApi
        const response = await api.getSchoolTermsAll(this.id)
        this.termFilterOptions = response.terms
      } catch {
        this.addNotification('error', 'There was a problem getting school terms')
      }
    },

    async getClasses() {
      try {
        this.tableLoading = true
        const {sortBy, sortDesc, page} = this.tableOptions
        let getActiveClasses = this.tabSelected === 0
        let getArchivedClasses = false
        if (this.tabSelected === 1) {
          getArchivedClasses = true
          getActiveClasses = null
        }
        let order = 'asc'
        let sort = 'displayName'
        const searchText = this.tableSearch === '' ? undefined : this.tableSearch
        if (sortBy[0]) {
          sort = sortBy[0]
          order = sortDesc[0] ? 'desc' : 'asc'
        }

        const api = this.userRole() === 'Horizon Admin' ? AdminSchoolApi : StaffSchoolApi

        const response = await api.getClasses(this.id, page, this.itemsPerPage, sort, order, searchText, this.tableActiveFilters, getActiveClasses, getArchivedClasses)
        this.totalUsers = response?.meta?.totalCount
        this.classesList = response.sections
      } catch {
        this.addNotification('error', 'There was a problem getting classes')
      } finally {
        this.pageLoadedOnce = true
        this.tableLoading = false
        this.onTableSearch = false
      }
    },

    onFilterChange(column, filterArray) {
      this.tableActiveFilters[column] = filterArray
      this.getClasses()
    },

    formatDateTable(date) {
      return moment.utc(date).format('LL')
    },

    resetSelectedClasses() {
      this.selectedClasses = []
    },

    resetPage() {
      this.tableOptions.page = 1
    },

    onCreateClass() {
      this.dialogCreateClass = true
    },

    onClassSettings(idProp) {
      if (this.userRole() === 'Horizon Admin') {
        this.$router.push({name: 'AdminClass', params: {sectionId: idProp, school: this.school}})
      } else {
        this.$router.push({name: 'TeacherAdminClass', params: {sectionId: idProp, school: this.school}})
      }
    },

    onDeleteDialog() {
      if (this.selectedClasses.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogDeleteConfirmation = true
      })
    },

    async onRemoveMultipleClasses() {
      try {
        this.deletionLoader = true
        const idList = this.selectedClasses.map(obj => obj.id)
        const api = this.userRole() === 'Teacher Admin' ? StaffSectionApi : AdminSectionApi
        await api.deletionBulk(idList)
        this.dialogDeleteConfirmation = false
        this.getClasses()
        this.resetPage()
        this.resetSelectedClasses()
        this.addNotification('success', 'Classes removed successfully')
      } catch {
        this.addNotification('error', 'Failed to remove the selected classes. Please try again.')
      } finally {
        this.deletionLoader = false
      }
    },

    onCloseCreateClassDialog(update) {
      if (update) {
        this.getClasses()
      }
      this.dialogCreateClass = false
    },

    onoCloseDialogDeleteConfirmation() {
      this.dialogDeleteConfirmation = false
    },

    onCloseDialogHideClasses() {
      this.dialogHideClass = false
    },

    onCloseDialogUnhideClasses() {
      this.dialogUnhideClass = false
    },

    // Hide/Unhide methods
    onHideClasses() {
      if (this.selectedClasses.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.dialogHideClass = true
    },

    onUnhideClasses() {
      if (this.selectedClasses.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.dialogUnhideClass = true
    },

    async onConfirmStatusChange(status) {
      try {
        this.actionLoader = true
        const idList = this.selectedClasses.map(obj => obj.id)
        const api = (this.userRole() === 'Horizon Admin') ? AdminSectionApi : StaffSectionApi

        await api.bulkStatusChange(idList, status)
        this.dialogHideClass = false
        this.dialogUnhideClass = false
        this.getClasses()
        this.resetSelectedClasses()
        if (status === false) {
          this.addNotification('success', 'Classes hidden successfully')
        } else {
          this.addNotification('success', 'Classes unhidden successfully')
        }
      } catch {
        if (status === false) {
          this.addNotification('error', 'Failed to hide the classes. Please try again.')
        } else {
          this.addNotification('error', 'Failed to unhide the classes. Please try again.')
        }
      } finally {
        this.actionLoader = false
      }
    },

    noSelectedUsersDisplay() {
      this.noSelectedUsers = true
      setTimeout(() => {
        this.noSelectedUsers = false
      }, 5000)
    },

    getTableHeight() {
      if (this.classesList.length < 6) return undefined

      if (this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 900) return '400px'

      return this.tableHeight
    },
  },
}
</script>

<style scoped>

</style>
