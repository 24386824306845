import AdminUsers from "@/components/admin/AdminUsers.vue";
import AdminClasses from "@/components/admin/AdminClasses.vue";
import AssessmentSchedules from "@/components/shared/AssessmentSchedules.vue";
import AdminSchoolSettings from "@/components/admin/AdminSchoolSettings.vue";
import AdminClass from "@/components/admin/AdminClass.vue";
import StaffAssessmentMultibuilder from "@/components/staff/StaffAssessmentMultibuilder.vue";
import AdminTerms from "@/components/admin/AdminTerms.vue";


export default [
    {
        path: '/admin/schools',
        name: 'Schools',
        component: () => import('@/components/SchoolList.vue'),
        meta: {
            breadcrumb: [
                {text: 'Schools'}
            ],
            roles: ['Horizon Admin', 'District Admin']
        }
    },
    {
        path: '/admin/schools/create',
        name: 'CreateSchool',
        component: () => import('@/components/CreateSchool.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Schools',
                    to: {name: 'Schools'}
                },
                {
                    text: 'Create New School',
                }

            ],
            roles: ['Horizon Admin', 'District Admin']
        }
    },
    {
        path: '/admin/schools/:id',
        props: true,
        component: () => import('@/components/admin/AdminDashboard.vue'),
        meta: {
            breadcrumb() {
                const school = this.$route.params.school;
                return [
                    {
                        text: 'Schools',
                        to: {name: 'Schools'}
                    },
                    {
                        text: school,
                    }

                ]
            },
            roles: ['Horizon Admin', 'District Admin']
        },
        children: [
            {
                path: '',
                name: '',
                redirect: {name: 'AdminDashboard/Users'},
            },
            {
                path: 'users',
                name: 'AdminDashboard/Users',
                component: AdminUsers,
                props: true,
                meta: {
                    breadcrumb() {
                        const school = this.$route.params.school;
                        return [
                            {
                                text: 'Schools',
                                to: {name: 'Schools'}
                            },
                            {
                                text: school,
                            }

                        ]
                    },
                    roles: ['Horizon Admin', 'District Admin']
                },
            },
            {
                path: 'classes',
                name: 'AdminDashboard/Classes',
                component: AdminClasses,
                props: true,
                meta: {
                    breadcrumb() {
                        const school = this.$route.params.school;
                        return [
                            {
                                text: 'Schools',
                                to: {name: 'Schools'}
                            },
                            {
                                text: school,
                            }

                        ]
                    },
                    roles: ['Horizon Admin', 'District Admin']
                },
            },
            {
                path: 'classes/:sectionId',
                name: 'AdminClass',
                component: AdminClass,
                props: true,
                meta: {
                    breadcrumb() {
                        const school = this.$route.params.school;
                        return [
                            {
                                text: 'Schools',
                                to: {name: 'Schools'}
                            },
                            {
                                text: school,
                            }

                        ]
                    },
                    roles: ['Horizon Admin', 'District Admin']
                },
            },
            {
                path: 'terms',
                name: 'AdminDashboard/Terms',
                props: true,
                component: AdminTerms,
                meta: {
                    breadcrumb() {
                        const school = this.$route.params.school;
                        return [
                            {
                                text: 'Schools',
                                to: {name: 'Schools'}
                            },
                            {
                                text: school,
                            }

                        ]
                    },
                    roles: ['Horizon Admin', 'District Admin'],
                },
            },
            {
                path: 'assessments',
                name: 'AdminDashboard/Assessments',
                props: true,
                component: AssessmentSchedules,
                meta: {
                    breadcrumb() {
                        const school = this.$route.params.school;
                        return [
                            {
                                text: 'Schools',
                                to: {name: 'Schools'}
                            },
                            {
                                text: school,
                            }

                        ]
                    },
                    roles: ['Horizon Admin', 'District Admin'],
                    fromSchoolDashboard: true
                },
            },
            {
                path: 'assessments/assign',
                name: 'AdminAssessmentMultibuilder',
                props: true,
                component: StaffAssessmentMultibuilder,
                meta: {
                    breadcrumb() {
                        const school = this.$route.params.school;
                        return [
                            {
                                text: 'Schools',
                                to: {name: 'Schools'}
                            },
                            {
                                text: school,
                            }

                        ]
                    },
                    roles: ['Horizon Admin', 'District Admin']
                },
            },
            {
                path: 'settings',
                name: 'AdminDashboard/SchoolSettings',
                component: AdminSchoolSettings,
                meta: {
                    breadcrumb() {
                        const school = this.$route.params.school;
                        return [
                            {
                                text: 'Schools',
                                to: {name: 'Schools'}
                            },
                            {
                                text: school,
                            }

                        ]
                    },
                    roles: ['Horizon Admin', 'District Admin']
                },
            },
        ]
    },
    {
        path: '/admin/user-search',
        name: 'AdminUserSearch',
        component: () => import('@/components/admin/AdminUserSearch.vue'),
        meta: {
            breadcrumb: [
                {text: 'User Search'}
            ],
            roles: ['Horizon Admin', 'District Admin']
        }
    },
    {
        path: '/admin/design',
        name: 'Design',
        component: () => import('@/components/DesignForDevs.vue'),
        meta: {
            breadcrumb: [
                {text: 'Design'}
            ],
            roles: ['Horizon Admin', 'District Admin']
        }
    },
    {
        path: '/admin/districts',
        name: 'Districts',
        component: () => import('@/components/DistrictList.vue'),
        meta: {
            breadcrumb: [
                {text: 'Districts'}
            ],
            roles: ['Horizon Admin', 'District Admin']
        }
    },
    {
        path: '/admin/districts/:id',
        name: 'DistrictDashboard',
        component: () => import('@/components/CreateDistrict.vue'),
        props: true,
        meta: {
            breadcrumb() {
                const district = this.$route.params.district;
                return [
                    {
                        text: 'Districts',
                        to: {name: 'Districts'}
                    },
                    {
                        text: district,
                    }

                ]
            },
            roles: ['Horizon Admin', 'District Admin']
        }
    },
    {
        path: '/admin/districts/create',
        name: 'CreateDistrict',
        component: () => import('@/components/CreateDistrict.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Districts',
                    to: {name: 'Districts'}
                },
                {
                    text: 'Create New District',
                }
            ],
            roles: ['Horizon Admin', 'District Admin']
        }
    },
    {
        path: '/admin/settings',
        name: 'Settings',
        component: () => import('@/components/admin/AdminSettings.vue'),
        meta: {
            breadcrumb: [
                {text: 'Settings'}
            ],
            roles: ['Horizon Admin', 'District Admin']
        }
    },
    {
        path: '/admin/courses',
        name: 'Courses',
        component: () => import('@/components/admin/AdminCourses.vue'),
        meta: {
            breadcrumb: [
                {text: 'Courses'}
            ],
            roles: ['Horizon Admin', 'District Admin']
        }
    },
    {
        path: '/admin/course-activities',
        name: 'AdminCourseActivities',
        component: () => import('@/components/admin/course/CourseActivity.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Courses',
                    to: 'Courses'
                },
                {
                    text: 'Course Activities'
                }
            ],
            roles: ['Horizon Admin', 'District Admin']
        }
    },
    {
        path: '/admin/course-activities/learnosity/:activityId',
        name: 'AdminCourseActivitiesLearnosity',
        component: () => import('@/components/admin/course/LearnosityActivity.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Courses',
                    to: 'Courses'
                },
                {
                    text: 'Course Activities',
                    to: 'Courses'
                },
                {
                    text: 'Learnosity'
                }
            ],
            roles: ['Horizon Admin', 'District Admin']
        }
    },
    {
        path: '/admin/sis_service_status',
        name: 'Server Status',
        component: () => import('@/components/admin/AdminSisServiceStatus.vue'),
        meta: {
            roles: ['Horizon Admin']
        }
    },
    {
        path: '/admin/user_masking',
        name: 'User Masking',
        component: () => import('@/components/admin/AdminUserMasking.vue'),
        meta: {
            roles: ['Horizon Admin']
        }
    },
    {
        path: '/admin/user_options',
        name: 'User Options',
        component: () => import('@/components/admin/AdminUserOptions.vue'),
        meta: {
            roles: ['Horizon Admin']
        }
    },
    {
        path: '/admin/analytics',
        name: 'AdminAnalytics',
        component: () => import('@/components/admin/AdminTestingSchedules.vue'),
        meta: {
            breadcrumb: [
                {text: 'Testing Schedules'}
            ],
            roles: ['Horizon Admin']
        },
        // in case we have more Analytics pages   
        // children: [
        //     {
        //         path: '',
        //         name: '',
        //     },
        // ]
    }
]
