<template>
  <v-dialog
    :value="dialog"
    max-width="800"
    persistent
  >
    <v-card class="card-dialog">
      <v-overlay :value="loaderProp">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        :title="title"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row v-if="dialog">
            <v-col>
              <label
                class="label-default label-default__small"
                for="startTime"
              >
                Classes
              </label>
              <v-autocomplete
                id="classes"
                v-model="lessonAssignmentData.sections"
                label="Select Classes"
                :disabled="!newAssignment"
                :menu-props="{ maxHeight: '200' }"
                dense
                multiple
                solo
                flat
                clearable
                class="input-default input-default__small input-default__height-unset mt-1"
                :return-object="true"
                item-value="id"
                item-text="displayName"
                :items="sectionList"
                :rules="lessonAssignmentData.rules.classes"
                hide-details="auto"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="startDate"
              >
                Start Date
              </label>
              <v-menu
                ref="menuStartDate"
                v-model="menuStartDate"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    id="startDate"
                    v-model="lessonAssignmentData.formattedStartAtDate"
                    class="input-default input-default__small mt-1"
                    placeholder="Select Start Date"
                    dense
                    flat
                    readonly
                    v-bind="attrs"
                    :rules="lessonAssignmentData.rules.start_at"
                    hide-details="auto"
                    required
                    v-on="on"
                  >
                    <template #append>
                      <v-icon
                        @click="menuStartDate = !menuStartDate"
                      >
                        mdi-calendar
                      </v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="lessonAssignmentData.startAtDate"
                  min="2023-01-01"
                  @input="menuStartDate = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="startTime"
              >
                Start Time
              </label>
              <v-menu
                ref="menuStartTime"
                v-model="menuStartTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value="lessonAssignmentData.startAtTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="lessonAssignmentData.startAtTime"
                    type="time"
                    class="input-default input-default__small mt-1"
                    placeholder="Input Start Time"
                    dense
                    flat
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @keydown.space="(event) => event.preventDefault()"
                  >
                    <template #append>
                      <v-icon
                        @click="menuStartTime = !menuStartTime"
                      >
                        mdi-clock-outline
                      </v-icon>
                    </template>
                  </v-text-field>
                </template>

                <v-time-picker
                  v-if="menuStartTime"
                  v-model="lessonAssignmentData.startAtTime"
                  full-width
                  scrollable
                  @click="$refs.menuStartTime.save(lessonAssignmentData.startAtTime)"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="endDate"
              >
                End Date (Optional)
              </label>
              <v-menu
                ref="menuEndDate"
                v-model="menuEndDate"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    id="endDate"
                    v-model="lessonAssignmentData.formattedEndAtDate"
                    class="input-default input-default__small mt-1"
                    placeholder="Select End Date"
                    dense
                    flat
                    clearable
                    readonly
                    v-bind="attrs"
                    :rules="lessonAssignmentData.rules.end_at"
                    hide-details="auto"
                    v-on="on"
                  >
                    <template #append>
                      <v-icon
                        @click="menuEndDate = !menuEndDate"
                      >
                        mdi-calendar
                      </v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="lessonAssignmentData.endAtDate"
                  min="2023-01-01"
                  @input="menuEndDate = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="endTime"
              >
                End Time (Optional)
              </label>
              <v-menu
                ref="menuEndTime"
                v-model="menuEndTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value="lessonAssignmentData.endAtTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="lessonAssignmentData.endAtTime"
                    type="time"
                    class="input-default input-default__small mt-1"
                    placeholder="Input End Time"
                    dense
                    clearable
                    flat
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @keydown.space="(event) => event.preventDefault()"
                  >
                    <template #append>
                      <v-icon
                        @click="menuEndTime = !menuEndTime"
                      >
                        mdi-clock-outline
                      </v-icon>
                    </template>
                  </v-text-field>
                </template>

                <v-time-picker
                  v-if="menuEndTime"
                  v-model="lessonAssignmentData.endAtTime"
                  full-width
                  @click="$refs.menuEndTime.save(lessonAssignmentData.endAtTime)"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row
            dense
            class="mt-10"
          >
            <v-col
              cols="12"
              sm="6"
              class="pr-sm-2"
            >
              <v-btn
                class="btn-default btn-default__secondary btn-default__small"
                block
                @click="onCloseDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pl-sm-2"
            >
              <v-btn
                dark
                class="btn-default btn-default__primary btn-default__small"
                block
                @click="onSubmitForm()"
              >
                <span v-if="newAssignment">Assign Lesson</span>
                <span v-else>Update Lesson</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import {validationMixin} from "vuelidate/src";
import {LessonAssignment} from '@/models';

export default {
  name: "DialogAssignLesson",

  components: {
    AppHeader
  },

  mixins: [validationMixin],
  props: {
    active: Boolean,
    loaderProp: Boolean,
    newAssignment: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    lesson: {
      type: Object,
      default() {
        return {}
      }
    },
    sectionList: {
      type: Array,
      default: () => [],
    },
    sectionClassList: {
      type: Array,
      default: () => [],
    },
    formValid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,
      valid: true,
      menuStartDate: false,
      menuEndDate: false,
      menuRevealDate: false,
      menuStartTime: false,
      menuEndTime: false,
      menuRevealTime: false,
      lessonAssignmentData: new LessonAssignment(),
      selectedSections: [],
    }
  },

  watch: {
    active(value) {
      if (value) {
        this.cleanLessonAssignmentForm()
        this.updateLessonAssignmentDates(this.lesson)
        this.dialog = value
      } else {
        this.dialog = value
      }
    },
    selectedSections(newVal) {
      this.$emit('update:sectionList', newVal);
    },
  },

  methods: {
    updateLessonAssignmentDates(lesson) {
      if (lesson.assignment_schedule) {
        if (lesson.assignment_schedule.start_at && lesson.assignment_schedule.end_at) {
          this.lessonAssignmentData.start_at = new Date(lesson.assignment_schedule.start_at)
          this.lessonAssignmentData.end_at = new Date(lesson.assignment_schedule.end_at)
        } else if (lesson.assignment_schedule.start_at) {
          this.lessonAssignmentData.start_at = new Date(lesson.assignment_schedule.start_at)
          this.lessonAssignmentData.end_at = null
        } else {
          this.lessonAssignmentData.start_at = new Date()
          this.lessonAssignmentData.end_at = null
        }
      } else {
        this.lessonAssignmentData.start_at = new Date()
        this.lessonAssignmentData.end_at = null
      }

      if (!this.newAssignment) {
        this.lessonAssignmentData.sections = [{displayName: lesson.section_name, id: lesson.section_id}]
      }
    },

    cleanLessonAssignmentForm() {
      this.lessonAssignmentData = new LessonAssignment()
    },

    onCloseDialog() {
      this.$emit('closeDialog')
    },

    onSubmitForm() {
      if (!this.$refs.form.validate()) return

      const timingData = {
        'start_at': this.lessonAssignmentData.start_at,
        'end_at': this.lessonAssignmentData.end_at,
        'class_ids': this.lessonAssignmentData.sections
      }
      this.$emit('assignLesson', timingData)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>