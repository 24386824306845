<template>
  <v-container>
    <div v-if="reportLoading">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
    </div>
    <div v-else-if="compositeReport?.studentAssessments?.length > 0">
      <v-banner
        sticky
        color="white"
      >
        <v-row>
          <v-col cols="8">
            <h1>{{ studentName }}</h1>
          </v-col>

          <v-col cols="4">
            <v-btn
              v-if="showBackToIndividualStudentReportsButton"
              rounded
              elevation="0"
              class="btn-go-back"
              block
              @click="goBackInHistory()"
            >
              Return to&nbsp;<u>Individual Student Performance Report</u>
            </v-btn>
          </v-col>
        </v-row>
      </v-banner>
      <student-report-composite
        :composite-report-prop="compositeReport"
        @downloadPdf="downloadPdf()"
      />
    </div>
    <div
      v-else
      class="pt-4"
    >
      <h4>This report does not exist, or the student did not complete both parts of the assessment section.</h4>
    </div>
  </v-container>
</template>


<script>
import StudentReportComposite from "@/components/student/reports/StudentReportComposite.vue";

import {StaffStudentAssessmentApi} from "@/api"

export default {
  components: {
    StudentReportComposite,
  },

  props: {
    studentId: {
      type: [Number, String],
      required: true
    },

    sectionId: {
      type: [Number, String],
      required: false,
      default: null
    },

    assessmentSetId: {
      type: [Number, String],
      required: true
    },

    assessmentTypeId: {
      type: [Number, String],
      required: true
    },

    year: {
      type: [Number, String],
      required: false,
      default: null
    }
  },

  computed: {
    showBackToIndividualStudentReportsButton() {
      return this.fromPage === 'Staff/Reports/Group/StudentPerformance'
    }
  },

  data() {
    return {
      reportLoading: true,
      compositeReport: null,
      questionsAndAnswers: null,
      studentName: '',
      fromPage: ''
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromPage = from.name
    })
  },

  mounted() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.reportLoading = true

      try {
        const response = await StaffStudentAssessmentApi.getCompositeReport({
          studentId: this.studentId,
          sectionId: this.sectionId,
          assessmentSetId: this.assessmentSetId,
          assessmentTypeId: this.assessmentTypeId,
          schoolYearId: this.year
        })
        this.compositeReport = response.compositeReport
        this.studentName = `${this.compositeReport.user.firstName} ${this.compositeReport.user.lastName}`
      } catch (e) {
        this.addNotification('error', "The report could not be loaded.")
      }

      this.reportLoading = false

    },

    downloadPdf() {
      this.addNotification('info', 'Generating PDF report...')
      StaffStudentAssessmentApi.downloadPdf({
        studentId: this.studentId,
        sectionId: this.sectionId,
        assessmentSetId: this.assessmentSetId,
        assessmentTypeId: this.assessmentTypeId,
        schoolYearId: this.year
      })
          .then(({filename, blob}) => {
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click();
          }, (responseError) => {
            this.addNotification('error', responseError)
          })
    },

    goBackInHistory() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-go-back {
  background-color: $themeMdGray;
  color: #345165;
  border-radius: 25px;
  font-size: 12px;
  text-transform: none;
  letter-spacing: normal !important;
}
</style>