<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="90%" max-height="90%">
      <v-card>
        <app-header
          title="Student Information System Sync Metadata Logs"
          :is-dialog="true"
          @closeDialog="closeDialog()"
        />
        <v-card-text>
         <v-row style="padding-top:25px;">
            <v-col class="d-flex align-center">
              <div class="label-default-container">
                <label class="label-default" for="sisSyncLogSelect">
                  Last 5 Syncs (Time in EST)
                </label>
              </div>
              <v-select
                id="sisSyncLogSelect"
                v-model="selectedSyncRecord"
                :items="sisSyncRecords"
                item-text="name"
                item-value="id"
                return-object
                class="input-default"
                dense
                flat
              />
            </v-col>
          </v-row>
          <v-row v-for="row in activeSyncs" style="padding-top:25px;" :key="row.id" class="summary-row">
            <v-col cols="2">
              <label class="label-default">
                {{row[0]['label']}}
              </label>
            </v-col>
            <v-col cols="1">
              <p class="form-input-text">
                {{row[0]['value']}}
              </p>
            </v-col>
            <v-col cols="2">
              <label class="label-default">
                {{row[1]['label']}}
              </label>
            </v-col>
            <v-col cols="1">
              <p class="form-input-text">
                {{row[1]['value']}}
              </p>
            </v-col>
            <v-col cols="2">
              <label class="label-default">
                {{row[2]['label']}}
              </label>
            </v-col>
            <v-col cols="4">
              <p class="form-input-text">
                {{row[2]['value'].join()}}
              </p>
            </v-col>
          </v-row>
          <v-row style="padding-top:25px;">
            <v-col>
              <label class="label-default">
                Local Updates
              </label>
              <v-tabs v-model="localActiveTab" show-arrows>
                <v-tab>User Imports</v-tab>
                <v-tab>User Syncs</v-tab>
                <v-tab>User Transfers</v-tab>
                <v-tab>User Errors</v-tab>
                <v-tab>Section Imports</v-tab>
                <v-tab>Section Syncs</v-tab>
                <v-tab>Section Enrolls</v-tab>
                <v-tab>Section Errors</v-tab>
                <v-tab>Term Imports</v-tab>
                <v-tab>Term Syncs</v-tab>
                <v-tab>Term Errors</v-tab>
                <v-tab>Generic Logs</v-tab>
              </v-tabs>
              <v-simple-table height="300px">
                <tbody>
                  <tr v-for="item in localActiveReport" :key="item.id">
                    <td class="simple-table-td"> {{ item }} </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label class="label-default">
                Remote Data
              </label>
              <v-tabs v-model="remoteDataActiveTab">
                <v-tab>User Data</v-tab>
                <v-tab>Section Data</v-tab>
                <v-tab>Term Data</v-tab>
              </v-tabs>
              <v-simple-table height="300px">
                <tbody>
                  <tr v-for="item in remoteDataActiveReport" :key="item.id">
                    <td class="simple-table-td"> {{ item }} </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark class="btn-default btn-default__primary" @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import {AdminSchoolApi} from "@/api"

export default {
  name: "DialogSisSyncMetaReports",
  components: {AppHeader},

  props: {
    active: Boolean,
  },

  data() {
    return {
      dialog: false,
      remoteDataSyncReport: [],
      localSyncReport: [],
      remoteDataActiveReport: [],
      localActiveReport: [],
      remoteDataActiveTab: 0,
      localActiveTab: 0,
      sisSyncRecords: [],
      selectedSyncRecord: null,
      activeSyncs: [
        [
          {label: 'Total SIS Users', value: '0'},
          {label: 'Stale User Records', value: '0'},
          {label: 'Stale User IDs', value: []}
        ],
        [
          {label: 'Total SIS Sections', value: '0'},
          {label: 'Stale Section Records', value: '0'},
          {label: 'Stale Section IDs', value: []}
        ],
      ]
    }
  },

  watch: {
    selectedSyncRecord(value) {
      if (value != null) {
        this.getSyncReport()
      }
    },

    active(value) {
      this.dialog = value
      this.getSisSyncRecordsList()
    },

    remoteDataActiveTab(value) {
      this.remoteDataActiveReport = this.remoteDataSyncReport[value]
    },

    localActiveTab(value) {
      this.localActiveReport = this.localSyncReport[value]
    },

  },

  methods: {

    async getSyncReport() {
      let res = await AdminSchoolApi.getSisSyncReport(this.$route.params.id, this.selectedSyncRecord.id)

      this.remoteDataSyncReport[0] = res.report.remote_data.user
      this.remoteDataSyncReport[1] = res.report.remote_data.section
      this.remoteDataSyncReport[2] = res.report.remote_data.term

      this.remoteDataActiveReport = this.remoteDataSyncReport[0]

      this.localSyncReport[0] = res.report.users.import
      this.localSyncReport[1] = res.report.users.sync
      this.localSyncReport[2] = res.report.users.transfer
      this.localSyncReport[3] = res.report.users.error

      this.localSyncReport[4] = res.report.sections.import
      this.localSyncReport[5] = res.report.sections.sync
      this.localSyncReport[6] = res.report.sections.enroll
      this.localSyncReport[7] = res.report.sections.error

      this.localSyncReport[8] = res.report.terms.import
      this.localSyncReport[9] = res.report.terms.sync
      this.localSyncReport[10] = res.report.terms.error
      this.localSyncReport[11] = res.report.generic.log

      let subjects = ['users', 'sections']
      let categories = ['total', 'stale', 'stale_ids']

      subjects.forEach((subject, i) => {
        categories.forEach((cat, j) => {
          this.activeSyncs[i][j]['value'] = res.report.freshness[subject][cat]
        })
      })

      this.localActiveReport = this.localSyncReport[0]
      this.remoteDataActiveTab = 0
      this.localActiveTab = 0
    },

    async getSisSyncRecordsList() {
      let res = await AdminSchoolApi.getSisSyncRecordList(this.$route.params.id)
      if (res.sync_records.length > 0) {
        this.sisSyncRecords = res.sync_records
        this.selectedSyncRecord = res.sync_records[res.sync_records.length - 1]
      }
    },

    closeDialog() {
      this.remoteDataSyncReport = []
      this.localSyncReport = []
      this.remoteDataActiveReport = []
      this.localActiveReport = []
      this.remoteDataActiveTab = 0
      this.localActiveTab = 0
      this.sisSyncRecords = []
      this.selectedSyncRecord = null
      this.activeSyncs = [
        [
          {label: 'Total SIS Users', value: '0'},
          {label: 'Stale User Records', value: '0'},
          {label: 'Stale User IDs', value: []}
        ],
        [
          {label: 'Total SIS Sections', value: '0'},
          {label: 'Stale Section Records', value: '0'},
          {label: 'Stale Section IDs', value: []}
        ],
      ]

      this.$emit('closeDialog')
    },

  },

}
</script>
<style scoped>
  .v-data-table {
    width: 100% !important;
  }
</style>
