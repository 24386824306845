<template>
  <div>
    <v-container
      class="mt-1"
      fluid
    >
      <app-header title="Course Activities" />
      <v-row v-if="courseActivity">
        <v-col>
          <div
            v-if="loadingCourseActivities"
          >
            <v-skeleton-loader
              max-width="700"
              tile
              type="list-item-two-line"
            />
            <v-skeleton-loader
              max-width="700"
              tile
              type="list-item-two-line"
            />
          </div>
          <course-activities-template 
            v-else
            :course-activity="courseActivity"
          />
        </v-col>
      </v-row>

      <v-row v-if="userRole !=='District Admin'">
        <v-col><h2>My Classes</h2></v-col>
      </v-row>
      <v-row v-if="userRole !=='District Admin'">
        <v-col v-if="classesLoading">
          <v-skeleton-loader
            type="card"
            height="100"
            class="mb-2"
          />
          <v-skeleton-loader
            type="card"
            height="100"
            class="mb-2"
          />
          <v-skeleton-loader
            type="card"
            height="100"
          />
        </v-col>
        <v-col v-else>
          <div
            v-for="item in sections"
            :key="item.id"
          >
            <div
              class="sheet-main"
              tabindex="0"
              :aria-label="item.displayName"
            >
              <div>
                <h4 class="section-name">
                  {{ item.displayName }}
                </h4>
                <div>
                  <v-chip
                    v-if="item.role"
                    class="chip-small mr-2"
                    :color="roleColor(item.role)"
                  >
                    <span class="chip-text font-weight-400">
                      {{ item.role }}
                    </span>
                  </v-chip>
                  <span class="color-orange font-weight-600 font-size-14">{{ item.studentCount }}</span> <span class="text-students">Students Enrolled</span>
                </div>
              </div>

              <div class="d-flex flex-wrap">
                <v-btn
                  class="btn-default btn-default__secondary mr-4 mt-1"
                  outlined
                  @click="onCourseAssignments(item)"
                >
                  <v-img
                    max-width="19"
                    max-height="19"
                    class="mr-2"
                    src="@/assets/icons/approve.png"
                    alt="Assignment Icon"
                  />
                  <span>Assignments</span>
                </v-btn>
                <v-btn
                  class="btn-default btn-default__secondary mt-1"
                  outlined
                  @click="onCourseGradebook(item)"
                >
                  <v-img
                    max-width="19"
                    max-height="19"
                    class="mr-2"
                    src="@/assets/icons/assessments_orange.png"
                    alt="Gradebook Icon"
                  />
                  Gradebook
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapGetters: mapUserGetters} = createNamespacedHelpers('user')
import AppHeader from "@/components/shared/AppHeader.vue";
import CourseActivitiesTemplate from "@/components/shared/CourseActivitiesTemplate.vue";
import {StaffSectionApi, StaffCourseActivitiesApi} from '@/api'
import {Section} from '@/models'

export default {
  name: "StaffCourseActivities",

  components: {
    AppHeader,
    CourseActivitiesTemplate
  },

  data() {
    return {
      classesLoading: false,
      loadingCourseActivities: false,
      sections: [],
      heCourseTemplateIds: null,
      courseActivity: '',
      courseText: '',
      sectionId: null,
    }
  },

  computed: {
    ...mapUserGetters(['user', 'userRole']),
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    })
  },

  mounted() {
    if(this.userRole !== 'District Admin') this.loadSections()
    this.loadCourseActivities()
  },

  methods: {

    async loadCourseActivities() {
      try {
        this.loadingCourseActivities = true
        const response = await StaffCourseActivitiesApi.listMinimal()
        this.courseActivity = response.course_activities
      } catch {
        this.addNotification('error', 'Failed to load activities.')
      } finally {
        this.loadingCourseActivities = false
      }
    },

    async loadSections() {
      try {
        this.classesLoading = true
        const response = await StaffSectionApi.list({
          archived: false
        })
        this.sections = response.sections.map(section => new Section(section))
      } catch {
        this.addNotification('error', 'Failed to load classes.')
      } finally {
        this.classesLoading = false
      }
    },

    viewCourseActivitiesClass(section) {
      this.$router.push({
        name: "StaffCourseActivitiesSchoolCourse",
        params: {
          sectionId: section.id,
          courseTemplate: section.heCourseTemplateId,
        }
      })
    },

    onCourseAssignments(section) {
      this.$router.push({
        name: "StaffCourseActivitiesAssignments",
        params: {
          sectionId: section.id,
          courseTemplate: section.heCourseTemplateId,
        }
      })
    },

    onCourseGradebook(section) {
      this.$router.push({
        name: "StaffCourseActivitiesGradebook",
        params: {
          sectionId: section.id,
          courseTemplate: section.heCourseTemplateId,
        }
      })
    },

    roleColor(role) {
      return role === 'Primary Teacher' ? '#d7e2eb' : '#d7ebe2'
    }
  }
}
</script>

<style scoped lang="scss">

.sheet-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100px;
  border: 1px #eaecf0 solid;
  padding: 10px 25px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.section-name {
  color: #2c495e;
  font-size: 20px;
  margin-bottom: 8px;
}

.text-students {
  font-size: 14px;
  color: #101828;
}

.v-data-footer {
  display: none;
}

.primary-teacher {
  background-color: #d7e2eb;
}

.secondary-teacher {
  background-color: #d7ebe2;
}

.enrolled_students {
  color: #f47e26;
}
</style>
