<template>
  <div>
    <v-container fluid>
      <v-row
        align="center"
        dense
      >
        <v-col
          cols="12"
          sm="auto"
        >
          <h3>
            Students
          </h3>
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          sm="auto"
        >
          <v-btn
            dark
            class="btn-default btn-default__secondary"
            block
            @click="rosterExport()"
          >
            <v-icon
              class="mr-2"
              size="19"
              color="#344054"
            >
              mdi-file-document-arrow-right-outline
            </v-icon>
            Export List
          </v-btn>
        </v-col>
        <v-col
          v-if="!cantEdit()"
          cols="12"
          sm="auto"
        >
          <v-btn
            dark
            class="btn-default btn-default__secondary"
            block
            @click="onUploadUsers()"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('@/assets/icons/importlist.png')"
              alt="icon-add"
              max-width="19"
              max-height="19"
            />
            Import List
          </v-btn>
        </v-col>
        <v-col
          v-if="!cantEdit()"
          cols="12"
          sm="auto"
        >
          <v-btn
            :loading="tableLoading"
            dark
            class="btn-default btn-default__primary"
            block
            @click="onAddStudentsDialog()"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('@/assets/icons/addadmin.png')"
              alt="icon-add"
              max-width="19"
              max-height="19"
            />
            Add Students
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div>
            <v-row
              dense
              class="table-header-extension"
            >
              <v-col
                cols="12"
                sm="6"
                md="4"
                xl="3"
                class="d-flex align-center"
              >
                <v-text-field
                  v-model="tableSearch"
                  placeholder="Search"
                  class="input-default input-default__table"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                  hide-details
                  solo
                  flat
                />
              </v-col>
              <v-spacer />
              <v-col
                v-if="!cantEdit()"

                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onClassTransfer()"
                >
                  Class Transfer
                </v-btn>
              </v-col>
              <v-col
                v-if="!cantEdit()"

                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onSetUntimed()"
                >
                  Set Untimed
                </v-btn>
              </v-col>
              <v-col
                v-if="!cantEdit()"

                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onSetTimed()"
                >
                  Set Timed
                </v-btn>
              </v-col>
              <v-col
                v-if="!cantEdit()"

                cols="12"
                sm="auto"
                class="d-flex align-center"
              >
                <v-btn
                  outlined
                  class="btn-table btn-table__text"
                  block
                  @click="onDeleteMultipleUsers()"
                >
                  Remove from Class
                </v-btn>
              </v-col>
              <v-col
                v-if="noSelectedUsers"
                cols="12"
              >
                <h6 class="color-danger text-right">
                  Please select at least one user!
                </h6>
              </v-col>
            </v-row>

            <v-data-table
              v-model="selectedUsers"
              :height="getTableHeight()"
              :show-select="!cantEdit()"
              item-key="id"
              :server-items-length="totalUsers"
              :options.sync="tableOptions"
              :headers="headers"
              :headers-length="headers.length + 1"
              :items="usersArray"
              :loading="tableLoading ? '#3d5d74' : false"
              class="table-default table-default__select table-radius-top-none table-default__header-fixed"
              fixed-header
              :footer-props="{
                showCurrentPage: true,
                itemsPerPageOptions:[itemsPerPage],
                disableItemsPerPage: true,
              }"
            >
              <template #[`footer.page-text`]="items">
                <span class="font-weight-700">{{ items.pageStart }}</span> to
                <span class="font-weight-700">{{ items.pageStop }}</span> of
                <span class="font-weight-700">{{ items.itemsLength }}</span>
              </template>

              <template
                v-for="header in headers"
                #[`header.${header.value}`]
              >
                <span
                  :key="header.text"
                  :aria-label="`Header: ${header.text}`"
                  tabindex="0"
                >
                  {{ header.text }}
                </span>
              </template>

              <template
                v-if="!isMobile"
                #[`item.firstName`]="{ item }"
              >
                <td>
                  <span class="mr-3">{{ item.firstName }}</span>
                  <span
                    v-if="item.untimedAssessment"
                    class="untimed-container"
                  >
                    Untimed
                  </span>
                </td>
              </template>

              <template
                v-if="!isMobile"
                #[`item.createdAt`]="{ item }"
              >
                <td>
                  <span>{{ formatDateTable(item.createdAt) }}</span>
                </td>
              </template>

              <template
                v-if="!isMobile"
                #[`item.lastLoginAt`]="{ item }"
              >
                <td>
                  <span>{{ formatDateTable(item.lastLoginAt) }}</span>
                </td>
              </template>

              <template
                v-if="!isMobile"
                #[`item.actions`]="{ item }"
              >
                <td>
                  <v-row dense>
                    <v-col class="d-flex justify-end">
                      <v-btn
                        v-if="canMasquerade()"
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        aria-label="Impersonate student"
                        @click="onConfirmMasqueradeDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-swap-horizontal
                        </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="canViewStudentProfile()"
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        aria-label="Open students profile modal"
                        @click="onStudentProfileDialog(item)"
                      >
                        <v-img
                          contain
                          :src="require('@/assets/icons/profile-primary.png')"
                          alt="icon-edit"
                          max-width="15"
                          max-height="15"
                        />
                      </v-btn>
                      <v-btn
                        v-if="!cantEdit()"
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        aria-label="Edit student modal"
                        @click="onEditDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-square-edit-outline
                        </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="!cantEdit()"
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        aria-label="Remove student"
                        @click="onDeleteDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </template>

              <template
                v-if="isMobile"
                #item="{ item, isSelected, select }"
              >
                <tr>
                  <td>
                    <ul class="flex-content">
                      <li
                        v-if="!cantEdit()"
                        class="flex-item"
                        data-label="Selected"
                      >
                        <v-simple-checkbox
                          :value="isSelected"
                          @click="select(!isSelected)"
                        />
                      </li>
                      <li
                        class="flex-item"
                        data-label="First Name"
                      >
                        {{ item.firstName }}
                        <span
                          v-if="item.untimedAssessment"
                          class="untimed-container"
                        >Untimed</span>
                      </li>
                      <li
                        class="flex-item"
                        data-label="Last Name"
                      >
                        {{ item.lastName }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="Grade"
                      >
                        {{ item.gradeLevel }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="Date Added"
                      >
                        {{ formatDateTable(item.createdAt) }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="Last Active"
                      >
                        {{ formatDateTable(item.lastLoginAt) }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="# of Logins"
                      >
                        {{ item.nrOfLogins }}
                      </li>
                      <v-col
                        cols="12"
                        class="d-flex"
                      >
                        <v-btn
                          v-if="canMasquerade()"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          aria-label="Impersonate student"
                          @click="onConfirmMasqueradeDialog(item)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-swap-horizontal
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="canViewStudentProfile()"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          aria-label="Open students profile modal"
                          @click="onStudentProfileDialog(item)"
                        >
                          <v-img
                            contain
                            :src="require('@/assets/icons/profile-primary.png')"
                            alt="icon-edit"
                            max-width="15"
                            max-height="15"
                          />
                        </v-btn>
                        <v-btn
                          v-if="!cantEdit()"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          aria-label="Edit student modal"
                          @click="onEditDialog(item)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-square-edit-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="!cantEdit()"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          aria-label="Remove student"
                          @click="onDeleteDialog(item)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </ul>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <dialog-student-profile
      :active="dialogStudentProfile"
      :student-prop="userProp"
      :from-class="true"
      :section-id="sectionId"
      :teacher-role="sectionParent?.role"
      @closeDialog="onCloseDialogStudentProfile()"
      @closeDialogUpdate="onCloseDialogStudentProfile(true)"
    />

    <dialog-student-form
      title="Edit Student"
      :active="dialogStudent"
      type="edit"
      :student-prop="userProp"
      :from-class="true"
      :section-id="sectionId"
      @closeDialog="onCloseDialogStudent()"
      @closeDialogUpdate="onCloseDialogStudent(true)"
    />

    <dialog-add-to-class
      :active="dialogAddToClass"
      :student-ids="studentIds"
      :section-id="sectionId"
      :school-id="schoolId"
      :is-transfer="true"
      @closeDialog="onCloseDialogAddToClass()"
      @closeDialogUpdate="onCloseDialogAddToClass(true)"
    />

    <dialog-add-students-to-class
      :active="dialogAddStudents"
      :section-id="sectionId"
      @closeDialog="onCloseAddStudentsDialog()"
      @closeDialogUpdate="onCloseAddStudentsDialog(true)"
    />

    <dialog-action
      :active="dialogSetUntimed"
      :loading="untimedLoader"
      title-prop="Set Untimed Confirmation"
      body-text-prop="Do you want the selected users to have Untimed Assessments?"
      @closeDialog="onCloseDialogSetUntimed()"
      @confirmDialog="onSetUntimedConfirmation()"
    />

    <dialog-action
      :active="dialogSetTimed"
      :loading="timedLoader"
      title-prop="Set Timed Confirmation"
      body-text-prop="Do you want the selected users to have Timed Assessments?"
      @closeDialog="onCloseDialogSetTimed()"
      @confirmDialog="onSetTimedConfirmation()"
    />

    <dialog-action
      :active="dialogMasquerade"
      :loading="masqueradeLoader"
      :body-text-prop="`Do you want to login as ${userProp.fullName} (${userProp.role})`"
      @closeDialog="onCloseDialogMasquerade()"
      @confirmDialog="onConfirmMasquerade()"
    />

    <dialog-delete-confirmation
      :active="dialogDeleteConfirmation"
      :deletion-loader-prop="deletionLoader"
      @closeDialog="onoCloseDialogDeleteConfirmation()"
      @confirmDeletion="onRemoveUser()"
    />

    <dialog-delete-confirmation
      :active="dialogBulkDeleteConfirmation"
      :deletion-loader-prop="deletionLoader"
      @closeDialog="onoCloseDialogDeleteConfirmation()"
      @confirmDeletion="onRemoveMultipleUsers()"
    />

    <dialog-upload-users
      title="Upload Student List"
      :active="dialogUploadUsers"
      :from-class="true"
      :school-id="schoolId"
      :section-id="sectionId"
      role="Student"
      @updateTable="getUsers()"
      @closeDialog="onCloseDialogUploadUsers()"
      @closeDialogUpdate="onCloseDialogUploadUsers(true)"
    />
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapActions, mapGetters} = createNamespacedHelpers('user')
const {mapState: mapStateStaff} = createNamespacedHelpers('staffSections')


import DialogStudentForm from "@/components/shared/DialogStudentForm.vue";
import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue";
import DialogAddToClass from "@/components/shared/DialogAddToClass.vue";
import DialogAction from "@/components/shared/DialogAction.vue";
import DialogAddStudentsToClass from "@/components/shared/DialogAddStudentsToClass.vue";
import {AdminSectionApi, AdminUserApi, StaffSectionApi} from "@/api";
import functions from "@/api/shared/functions";
import debounce from 'lodash/debounce'
import moment from "moment/moment";
import DialogUploadUsers from "@/components/shared/DialogUploadUsers.vue";
import DialogStudentProfile from "@/components/shared/DialogStudentProfile.vue";

export default {
  name: "StudentList",
  components: {
    DialogStudentProfile,
    DialogUploadUsers,
    DialogAddStudentsToClass,
    DialogStudentForm,
    DialogDeleteConfirmation,
    DialogAddToClass,
    DialogAction
  },

  props: {
    sectionId: {
      type: [Number, String],
      default: null
    },
    schoolId: {
      type: [Number, String],
      default: null
    },
    sectionEditable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      tableLoading: true,
      untimedLoader: false,
      timedLoader: false,
      onTableSearch: false,
      noSelectedUsers: false,
      masqueradeLoader: false,
      deletionLoader: false,
      dialogDeleteConfirmation: false,
      dialogBulkDeleteConfirmation: false,
      dialogStudent: false,
      dialogStudentProfile: false,
      dialogAddStudents: false,
      dialogAddToClass: false,
      dialogSetUntimed: false,
      dialogSetTimed: false,
      dialogMasquerade: false,
      dialogLoginAsAnotherUser: false,
      dialogUploadUsers: false,
      dialogUploadTitle: 'Upload Student List',
      userProp: {},
      sectionParent: {},

      totalUsers: 0,
      tableOptions: {
        itemsPerPage: 100,
        page: 1,
        sortBy: ['lastName'],
        sortDesc: [false]
      },
      itemsPerPage: 100,
      usersArray: [],
      studentIds: [],
      tableHeight: 'calc(90vh - 544px)',
      tabSelected: 0,
      selectedUsers: [],
      tableSearch: '',
      headers: [
        {
          text: 'First Name',
          value: 'firstName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Last Name',
          value: 'lastName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Grade',
          value: 'gradeLevel',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Date Added',
          value: 'createdAt',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Last Active',
          value: 'lastLoginAt',
          align: 'left',
          sortable: true,
        },
        {
          text: '# of Logins',
          value: 'nrOfLogins',
          align: 'left',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 180,
        }
      ],
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
  },

  watch: {
    tableSearch: {
      handler: debounce(function () {
        // Set page to 1 before searching
        this.resetPage()
        this.onTableSearch = true
        this.getUsers()
      }, 500),
    },

    tableOptions: {
      handler() {
        if (!this.onTableSearch && this.pageLoadedOnce) {
          this.getUsers()
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.getUsers()
    if (this.userRole() === 'Teacher' || this.userRole() === 'Teacher Admin') {
      this.sectionParent = this.allSections().find(section => section.id == this.sectionId)
    }

    if (this.cantEdit() && !this.canViewStudentProfile() && !this.canMasquerade()) {
      this.headers.pop()
    }
  },

  methods: {
    ...mapGetters(["userMasqId", "userRole", "user", "userMasqIdent"]),
    ...mapActions(['createUserSession']),
    ...mapStateStaff(['allSections']),

    async getUsers() {
      try {
        this.usersArray = []
        this.totalUsers = 0
        const searchText = this.tableSearch === '' ? undefined : this.tableSearch
        const {sortBy, sortDesc, page} = this.tableOptions
        let order = undefined
        if (sortBy[0]) {
          if (sortDesc[0]) {
            order = 'desc'
          } else {
            order = 'asc'
          }
        }
        this.tableLoading = true
        let response = {}
        if (this.userRole() === 'Horizon Admin') {
          response = await AdminSectionApi.getStudents(this.sectionId, page, this.itemsPerPage, sortBy[0], order, searchText)
        } else {
          response = await StaffSectionApi.getStudents(this.sectionId, page, this.itemsPerPage, sortBy[0], order, searchText)
        }
        this.totalUsers = response?.meta?.totalCount
        this.usersArray = response?.students
      } catch {
        this.addNotification('error', 'There was a problem getting students')
      } finally {
        this.pageLoadedOnce = true
        this.tableLoading = false
        this.onTableSearch = false
      }
    },

    formatDateTable(date) {
      if (date === 'Never') return 'Never'
      return moment.utc(date).format('LL')
    },

    cantEdit() {
      if (!this.sectionEditable && !this.isAdmin()) {
        return true
      }

      if (this.userRole() === 'Teacher' && this.user().school?.schoolAdminOnly) {
        return true
      }
      
      return this.sectionParent?.role === "Secondary Teacher" && this.userRole() !== 'Teacher Admin'
    },

    canMasquerade() {
      return !this.userMasqIdent() && (this.isAdmin() || this.userRole() === 'Teacher Admin' || this.sectionParent?.role === "Primary Teacher" || this.userRole() === "District Admin")
    },

    canViewStudentProfile() {
      return this.isAdmin() || this.userRole() === 'Teacher Admin' || this.sectionParent?.role === "Primary Teacher" || this.userRole() === "District Admin"
    },

    isAdmin() {
      return this.userRole() === 'Horizon Admin'
    },

    //  Student Profile methods
    onStudentProfileDialog(user) {
      this.userProp = functions.deepCopySync(user)
      this.dialogStudentProfile = true
    },

    onCloseDialogStudentProfile(updateTable) {
      if (updateTable) {
        this.getUsers()
        this.resetSelectedUsers()
      }
      this.dialogStudentProfile = false
    },


    // Upload methods
    onUploadUsers() {
      this.dialogUploadUsers = true
    },

    onCloseDialogUploadUsers(updateTable) {
      if (updateTable) {
        this.getUsers()
        this.resetSelectedUsers()
      }
      this.dialogUploadUsers = false
    },

    // Add/edit methods
    onAddStudentsDialog() {
      this.dialogAddStudents = true
    },

    onCloseAddStudentsDialog(update) {
      if (update) {
        this.getUsers()
      }
      this.dialogAddStudents = false
    },

    onEditDialog(user) {
      this.userProp = functions.deepCopySync(user)
      this.dialogStudent = true
    },

    onCloseDialogStudent(updateTable) {
      if (updateTable) {
        this.getUsers()
        this.resetSelectedUsers()
      }
      this.dialogStudent = false
    },

    //  Delete methods
    onDeleteDialog(userProp) {
      this.userProp = userProp
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogDeleteConfirmation = true
      })
    },

    async onRemoveUser() {
      try {
        this.deletionLoader = true
        if (this.userRole() === 'Horizon Admin') {
          await AdminSectionApi.removeUserBulk(this.sectionId, [this.userProp.id])
        } else {
          await StaffSectionApi.unassignUserBulk(this.sectionId, [this.userProp.id])
        }
        this.dialogDeleteConfirmation = false
        this.getUsers()
        this.resetSelectedUsers()
        this.addNotification('success', 'Student removed successfully')
      } catch {
        this.addNotification('error', 'Failed to remove the student. Please try again.')
      } finally {
        this.deletionLoader = false
      }
    },

    onDeleteMultipleUsers() {
      if (this.selectedUsers.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.noSelectedUsers = false
      this.dialogBulkDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogBulkDeleteConfirmation = true
      })
    },

    async onRemoveMultipleUsers() {
      try {
        this.deletionLoader = true
        const idList = this.selectedUsers.map(obj => obj.id)
        if (this.userRole() === 'Horizon Admin') {
          await AdminSectionApi.removeUserBulk(this.sectionId, idList)
        } else {
          await StaffSectionApi.unassignUserBulk(this.sectionId, idList)
        }
        this.dialogBulkDeleteConfirmation = false
        this.getUsers()
        this.resetPage()
        this.resetSelectedUsers()
        this.addNotification('success', 'Students removed successfully')
      } catch (error) {
        this.addNotification('error', 'Failed to remove the selected students. Please try again.')
      } finally {
        this.deletionLoader = false
      }
    },

    onoCloseDialogDeleteConfirmation() {
      this.dialogDeleteConfirmation = false
      this.dialogBulkDeleteConfirmation = false
    },

    // Class methods
    onClassTransfer() {
      if (this.selectedUsers.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.noSelectedUsers = false
      this.studentIds = this.selectedUsers.map(obj => obj.id)
      this.dialogAddToClass = true
    },

    onCloseDialogAddToClass(updateTable) {
      if (updateTable) {
        this.getUsers()
        this.resetSelectedUsers()
      }
      this.dialogAddToClass = false
    },

    // Untimed methods
    onSetUntimed() {
      if (this.selectedUsers.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.noSelectedUsers = false
      this.dialogSetUntimed = true
    },

    async onSetUntimedConfirmation() {
      try {
        const idList = this.selectedUsers.map(obj => obj.id)
        this.untimedLoader = true
        if (this.userRole() === 'Horizon Admin') {
          await AdminUserApi.setUntimedBulk(idList)
        } else {
          await StaffSectionApi.setUntimed(this.sectionId, idList)
        }
        this.dialogSetUntimed = false
        this.getUsers()
        this.resetSelectedUsers()
        this.addNotification('success', 'Students untimed successfully')
      } catch {
        this.addNotification('error', 'Failed to untime the student. Please try again.')
      } finally {
        this.untimedLoader = false
      }
    },

    onCloseDialogSetUntimed() {
      this.dialogSetUntimed = false
    },


    // Set Timed methods
    onSetTimed() {
      if (this.selectedUsers.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.noSelectedUsers = false
      this.dialogSetTimed = true
    },

    async onSetTimedConfirmation() {
      try {
        const idList = this.selectedUsers.map(obj => obj.id)
        this.timedLoader = true
        if (this.userRole() === 'Horizon Admin') {
          await AdminUserApi.setTimedBulk(idList)
        } else {
          await StaffSectionApi.setTimed(this.sectionId, idList)
        }
        this.dialogSetTimed = false
        this.getUsers()
        this.resetSelectedUsers()
        this.addNotification('success', 'Students set to timed assessments successfully')
      } catch {
        this.addNotification('error', 'Failed to update the student. Please try again.')
      } finally {
        this.timedLoader = false
      }
    },

    onCloseDialogSetTimed() {
      this.dialogSetTimed = false
    },

    // Masquerade methods
    onConfirmMasqueradeDialog(user) {
      this.userProp = user
      this.dialogMasquerade = true
    },

    async onConfirmMasquerade() {
      try {
        this.masqueradeLoader = true
        let payload = {
          login: {
            user_id: this.userProp.id,
            email: this.userProp.email,
            password: this.userProp.email,
            masqid: this.userMasqId()
          }
        }
        await this.createUserSession(payload)

        this.$emit('onMasquerade')

        if (this.userProp.role === "Student") {
          this.$router.push({name: "StudentCourses"})
        } else {
          this.$router.push({name: "StaffDashboard"})
        }

        
        this.addNotification('success', `You are logged in as ${this.userProp.fullName}(${this.userProp.role})`)
      } catch (error) {
        this.addNotification('error', `Failed to login as ${this.userProp.fullName}. Please try again.`)
      } finally {
        this.masqueradeLoader = false
      }
    },

    onCloseDialogMasquerade() {
      this.dialogMasquerade = false
    },

    resetSelectedUsers() {
      this.selectedUsers = []
    },

    resetPage() {
      this.tableOptions.page = 1
    },

    noSelectedUsersDisplay() {
      this.noSelectedUsers = true
      setTimeout(() => {
        this.noSelectedUsers = false
      }, 5000)
    },

    async rosterExport() {
      StaffSectionApi.studentRosterExport({sectionId: this.sectionId})
          .then(({filename, blob}) => {
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click();
          }, (responseError) => {
            this.addNotification('error', responseError)
          })
    },

    getTableHeight() {
      if (this.usersArray.length < 6) return undefined

      if(this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 900) return '400px'

      return this.tableHeight
    },

  },
}
</script>

<style scoped>

</style>
