<template>
  <div
    v-if="scoreReportLoading"
  >
    <v-skeleton-loader
      type="table-thead, image, list-item-avatar@3"
    />
  </div>

  <div
    v-else
  >
    <v-container
      class="container-reports"
    >
      <div
        v-if="showHeader"
      >
        <app-header
          :title="`${compositeReport.assessmentType?.course_type?.toUpperCase()} ${compositeReport.assessmentSet?.name}`"
        />
      </div>
      <!-- Composite Score -->
      <div class="section-header">
        <h1>Composite Score</h1>

        <v-spacer />
        <v-btn
          dark
          class="btn-default btn-default__primary"
          @click="downloadPdf"
        >
          Download Report
        </v-btn>
      </div>
      <div class="composite-score report-section">
        <div
          class="muted-text mx-5 mt-2"
        >
          Time Spent: {{ compositeReport?.timeSpent }}<br />
        </div>
        <div class="score-breakdown-row">
          <div class="donut-chart-wrapper w-35">
            <donut-chart
              :chart-id="'compositeReport'"
              :chart-title="'Composite Score'"
              :is-average-score="false"
              :chart-range="true"
              :average-score="compositeReport?.compositeScore"
              :min-score="compositeReport?.minScore"
              :max-score="compositeReport?.maxScore"
              :color="'#f4701f'"
            />
          </div>
          <div class="composite-stat-wrapper my-auto">
            <div class="composite-stats">
              <div class="stat-text-wrapper">
                <div class="stat-lg">
                  {{ compositeReport.percentile }}<span
                    class="superscript-lg"
                  >{{ getOrdinalSuffix(compositeReport.percentile) }}</span>
                </div>
                <div class="stat-sub-text">
                  Nationally Representative Sample Percentile
                </div>
              </div>
              <div
                v-if="satRelated"
                class="stat-text-wrapper"
              >
                <div class="stat-lg">
                  {{ compositeReport.userPercentile }}<span
                    class="superscript-lg"
                  >{{ getOrdinalSuffix(compositeReport.userPercentile) }}</span>
                </div>
                <div class="stat-sub-text">
                  SAT User Percentile
                </div>
              </div>
            </div>
            <div class="composite-score-description">
              <template v-if="satRelated">
                <div class="composite-description-header">
                  How do my scores compare?
                </div>
                <!-- eslint-disable max-len -->
                <div class="composite-description-body sm">
                  A percentile shows how you scored, compared to other students. It’s a number between 1 and 99 and
                  represents the percentage of students whose scores are equal to or below yours. For example, if your
                  Math percentile is 57, that means 57% of test takers have Math scores equal to or below yours.
                </div>
                <div class="composite-description-body sm">
                  <span class="highlight">The Nationally Representative Sample Percentile</span> compares your score to
                  the scores of typical U.S. students.
                </div>
                <div class="composite-description-body sm">
                  <span class="highlight">SAT® User Percentile</span> compares your score to the scores of students who
                  typically take the test.
                </div>
              </template>
              <template v-else>
                <div class="composite-description-header">
                  How do my scores compare?
                </div>
                <div class="composite-description-body">
                  Your ranks tell you the approximate percentages of recent high school graduates in the US who took the
                  ACT® test and received scores that are the same as or lower than your scores. For example, a rank of
                  56 for your Composite score means 56% of students earned that Composite score or below.
                </div>
                <!-- eslint-enable max-len -->
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- end Composite Score -->

      <!-- Section Scores -->
      <div class="section-header">
        <h1>Section Scores</h1>
      </div>
      <div class="section-scores report-section">
        <div class="no-gutters row score-breakdown-row">
          <!-- ACT: 'English', 'Math', 'Reading', 'Science'
          {{assessmentTypeProp}}: 'Reading & Writing' 'Math' -->
          <div
            v-for="(studentAssessment, sIndx) in compositeReport.studentAssessments"
            :key="sIndx"
            class="col-6"
          >
            <div class="section-header-sm">
              <h2>{{ studentAssessment?.report?.summary?.category }} Score</h2>
              <div
                class="muted-text"
              >
                Time Spent: {{ studentAssessment?.report?.summary?.timeSpent }}<br />
                Benchmark Score: {{ studentAssessment?.report?.summary?.benchmarkScore }}
              </div>
            </div>
            <div class="stat-row">
              <div class="donut-chart-wrapper w-50">
                <donut-chart
                  :chart-id="studentAssessment?.report?.summary?.category?.replaceAll(' ','-')+'-score'"
                  chart-title-font-size="58px"
                  :average-score="studentAssessment?.report?.summary?.convertedScore"
                  :is-average-score="false"
                  :min-score="studentAssessment?.report?.summary?.minScore"
                  :max-score="studentAssessment?.report?.summary?.maxScore"
                  :color="getCategoryColor(studentAssessment?.report?.summary?.category)"
                />
              </div>
              <div class="stat-wrapper w-50">
                <div class="benchmark-reached-wrapper">
                  <span
                    v-show="studentAssessment?.report?.summary?.convertedScore >= studentAssessment.report.summary.benchmarkScore"
                    class="benchmark-reached"
                  >
                    You've Reached the Benchmark Score!
                  </span>
                </div>
                <div class="stats">
                  <div class="stat-text-wrapper">
                    <div class="stat">
                      {{ studentAssessment?.report?.summary?.percentile }}<span
                        class="superscript"
                      >{{ getOrdinalSuffix(studentAssessment?.report?.summary?.percentile) }}</span>
                    </div>
                    <div class="sub-text">
                      Nationally Representative Sample Percentile
                    </div>
                  </div>
                  <div
                    v-if="satRelated"
                    class="stat-text-wrapper"
                  >
                    <div class="stat">
                      {{ studentAssessment?.report?.summary?.userPercentile }}<span class="superscript">{{
                        getOrdinalSuffix(studentAssessment?.report?.summary?.userPercentile)
                      }}</span>
                    </div>
                    <div class="sub-text">
                      SAT User Percentile
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end Section Scores -->

      <!-- Top Opportunities -->
      <div class="section-header">
        <h1>Top Opportunities</h1>
      </div>
      <div class="score-breakdown-row vertical">
        <div
          v-for="(category, cIdx) in compositeReport.topOpportunities"
          :key="cIdx"
        >
          <h1 v-if="category.opportunities.length > 0"
            class="my-3">
            {{ category.category }}
          </h1>
          <div
            v-for="(opportunity, oIndx) in category.opportunities"
            :key="oIndx"
            class="opportunity"
          >
            <div class="number-circle">
              {{ oIndx + 1 }}
            </div>
            <!-- Not sure how these messages are coming in - may need more keys to style the messages out properly -->
            <div class="body-larger">
              Work on <span class="call-out">{{ opportunity.subsectionAreaName }}</span> - {{ opportunity.topicAreaName }}
              in the <span class="call-out">{{ category.category }}</span> section.
            </div>
          </div>
        </div>
      </div>
      <!-- end Top Opportunities -->

      <!-- Subsection Details -->
      <div class="section-header bg-primary">
        <h1>Subsection Details</h1>
      </div>
      <v-container fluid>
        <div class="instructions">
          Click on each tab to view individual subsection details.
        </div>
        <v-tabs
          v-model="tab"
          :vertical="isMobile"
          :grow="isMobile"
          active-class="active-tab"
          hide-slider
          show-arrows
        >
          <v-tab
            v-for="studentAssessment in compositeReport.studentAssessments"
            :key="studentAssessment.id"
          >
            {{ studentAssessment.report.summary.category }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="studentAssessment in compositeReport.studentAssessments"
            :key="studentAssessment.id"
            :transition="false"
          >
            <v-sheet outlined>
              <student-report-individual
                :student-assessment-id="studentAssessment.id"
                :score-report-prop="studentAssessment.report"
                :multipart="true"
                :show-header="false"
                :from-composite="true"
                :student-id="studentId"
                :assessment-type="compositeReport.assessmentType.assessment_type"
              />
            </v-sheet>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <!-- end Subsection Details -->
    </v-container>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapGetters: mapGettersUser} = createNamespacedHelpers('user')
import AppHeader from "@/components/shared/AppHeader.vue";
import DonutChart from '@/components/shared/charts/DonutChart'
import StudentReportIndividual from './StudentReportIndividual.vue'
import {StudentScoreReportApi} from '@/api'
import Mixin from "@/mixins/mixin.js"

export default {
  components: {
    AppHeader,
    StudentReportIndividual,
    DonutChart
  },
  mixins: [Mixin],
  props: {
    assessmentTypeId: {type: [Number, String], default: null, required: false},
    assessmentSetId: {type: [Number, String], default: null, required: false},
    compositeReportProp: {type: Object, default: null, required: false},
    showHeader: {type: Boolean, default: true, required: false},
    studentId: {type: [Number, String], default: null, required: false},
    year: {type: [Number, String], default: null, required: false},
  },
  data() {
    return {
      tab: null,
      scoreReport: {},
      compositeReport: {},
      scoreReportLoading: true
    }
  },
  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),

    satRelated() {
      try {
        return this.compositeReport.assessmentType.assessment_type == "SAT"
      } catch (e) {
        return false
      }
    },
    actRelated() {
      try {
        return this.compositeReport.assessmentType.assessment_type == "ACT"
      } catch (e) {
        return false
      }
    }

  },

  created() {
    if (this.compositeReportProp != null) {
      this.compositeReport = this.compositeReportProp
      this.scoreReportLoading = false
    } else {
      this.loadReport(this.assessmentTypeId, this.assessmentSetId)
    }
  },

  methods: {
    ...mapGettersUser(["userRole"]),

    async loadReport(assessmentType, assessmentSet) {
      const response = await StudentScoreReportApi.getCompositeReport(
          {
            assessmentType: assessmentType,
            assessmentSet: assessmentSet,
            schoolYearId: this.year
          })
      this.compositeReport = response.compositeReport
      this.scoreReportLoading = false
    },

    getCategoryColor(section) {
      section = section.toLowerCase()
      return section == 'math' || section == 'science' || section == 'stem' ? '#568fce' : '#2cbc3b'
    },

    downloadPdf() {
      if (this.userRole() === "Student") {
        this.addNotification('info', 'Generating PDF report...')
        StudentScoreReportApi.downloadPdf({
          assessmentType: this.compositeReport.assessmentType.id,
          assessmentSet: this.compositeReport.assessmentSet.id,
          schoolYearId: this.year
        })
            .then(({filename, blob}) => {
              let link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = filename
              link.click();
            }, (responseError) => {
              this.addNotification('error', responseError)
            })
      } else {
        this.$emit("downloadPdf")
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.v-tab {
  text-transform: none !important;
  letter-spacing: 0;
  cursor: pointer;
  transition: .2s;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: #aaa !important;
  line-height: 24px;
  padding: 13px 32px;
  text-align: center;
  font-size: 18px;
}

.active-tab {
  background-color: #38566b;
  color: #fff !important;
}

.score-breakdown-row {
  padding: 20px 0 10px 20px;
  display: flex;

  > .col-6:nth-child(even) {
    padding-left: 20px;
    border-left: 1px solid #e8e8e8;
  }

  > .col-6:nth-child(odd) {
    padding-right: 20px;
  }
}

.w-35 {
  width: 35%
}

.w-65 {
  width: 65%
}

.w-50 {
  width: 50%
}

.composite-score-description {
  padding-top: 10px;

  .composite-description-header {
    font-size: 14px;
    font-weight: 700;
    color: $primaryColor;
  }

  .composite-description-body {
    font-size: 12px;
    line-height: 18px;
    padding-top: 10px;
    color: $themeGray;

    &.sm {
      font-size: 10px;
      line-height: 16px;
    }

    .highlight {
      color: $primaryColor;
      font-weight: 700;
    }
  }
}

.benchmark-reached {
  color: $themeOrange;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}

// override from statistics.scss
.stat-wrapper {
  margin-left: 30px;
  padding-left: inherit;
}

.stat-wrapper .stats {
  margin-top: 15px;
  padding: 0;
}

.composite-score {
  .score-breakdown-row {
    display: flex;

    .inner-donut-text {
      //font-size: 72px;
    }

    .donut-chart-wrapper {
      width: 40%;
    }

    .composite-stat-wrapper {
      width: 65%;
      padding: 0px 0px 0px 40px;
      margin: 0px;

      .stat-text-wrapper {
        width: 50%;
        padding: 0px;
        margin: 0px;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .composite-score {
    .score-breakdown-row {
      padding-left: 0;

      .inner-donut-text {
        font-size: 50px !important;
        color: red !important;
      }

      .composite-stat-wrapper {
        padding-left: 20px;

        .stat-text-wrapper {
          .stat-lg {
            font-size: 50px;
            line-height: 57px;
          }
        }
      }
    }
  }

  .section-scores {
    .score-breakdown-row {
      padding-left: 0;
    }

    .score-breakdown-row {
      .col-6:nth-child(even) {
        padding-left: 10px;
      }

      .col-6:nth-child(odd) {
        padding-right: 0;
      }

      .stat-wrapper {
        margin-left: 15px;

        .stat-text-wrapper {
          padding-left: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {

  .container-reports {
    padding: 10px !important
  }

  .composite-score {
    .score-breakdown-row {
      display: block;

      .donut-chart-wrapper {
        width: 100%;
        margin-bottom: 20px;
      }

      .composite-stat-wrapper {
        width: 100%;
        padding: 0;

        .stat-text-wrapper {
          .stat-lg {
            font-size: 80px;
            line-height: 87px;
          }
        }
      }
    }
  }

  .section-scores {
    .score-breakdown-row {
      display: block;

      .col-6 {
        display: block;
        width: 100%;
        max-width: 100%;
        border: 0;
        padding: 0 !important;
      }

      .stat-wrapper {
        margin-left: 15px;

        .stat-text-wrapper {
          padding-right: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .section-header {
    h1 {
      font-size: 18px !important;
    }
  }
}

.stat-row {
  padding: 20px 0;
  display: flex;

  &.left-padding {
    padding-left: 20px;
  }
}

.stat-wrapper {
  padding-left: 25px;

  .stats {
    display: flex;
    padding: 20px 0;
    justify-content: space-between;

    .stat-text-wrapper {
      max-width: 50%
    }
  }
}

.composite-stat-wrapper {
  padding-left: 50px;

  .composite-stats {
    display: flex;
    justify-content: space-between;

    .stat-text-wrapper {
      max-width: 50%
    }
  }
}

.stat-lg {
  font-size: 80px;
  line-height: 87px;
  color: $primaryColor;
  font-weight: 800;
  position: relative;

  .superscript-lg {
    position: absolute;
    top: 0;
    color: $themeOrange;
    font-weight: 800;
    font-size: 18px;
    line-height: 2.5em;
  }
}

.stat {
  font-size: 36px;
  line-height: 50px;
  color: $primaryColor;
  font-weight: 800;
  position: relative;

  .superscript {
    position: absolute;
    top: 0;
    color: $themeOrange;
    font-weight: 800;
    font-size: 12px;
    line-height: 2.5em;
  }
}

.stat-sub-text {
  color: $themeGray;
  font-size: 12px;
  line-height: 16px;
}

.section-header {
  display: flex;
  align-items: center;
  background-color: $themeMdGray;
  padding: 10px 20px;
}

.section-header-sm {
  padding: 8px 0;

  h2 {
    margin-bottom: 5px;
    line-height: 27px;
  }
}

.report-date-wrapper {
  display: flex;
  align-items: center;
  color: $subHeadingColor;

  img {
    width: 19px;
    height: 19px;
    margin-bottom: 5px;
  }
}

</style>

<style lang="scss">

.section-scores {
  .donut {


    @media screen and (max-width: $mobileScreenExtraLarge) {
      .inner-donut-text {
        font-size: 48px;
      }
    }

    @media screen and (max-width: $mobileScreenLarge) {
      .inner-donut-text {
        font-size: 48px;
      }
    }

    @media screen and (max-width: $mobileScreenMedium) {
      .inner-donut-text {
        font-size: 56px;
      }
    }

    @media screen and (max-width: $mobileScreenSmall) {
      .inner-donut-text {
        font-size: 48px;
      }
    }

    @media screen and (max-width: $mobileScreenExtraSmall) {
      .inner-donut-text {
        font-size: 42px;
      }
    }
  }
}
</style>


<style scoped lang="scss">

.container-reports {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  color: $primaryColor;
  margin-bottom: 0;
  line-height: 1.2;
}

h2, .h2 {
  font-size: 18px;
  font-weight: 700;
  color: $primaryColor;
  margin-bottom: 0;
}

h3, .h3 {
  font-size: 12px;
  margin-bottom: 0;
}

h4 {
  font-size: 10px;
  font-weight: 600;
  color: $subHeadingColor;
  margin-bottom: 0;
}

h5 {
  font-size: 10px;
  font-weight: 400;
  color: $subHeadingColor;
  margin-bottom: 0;
}

.sub-text {
  font-size: 10px;
}

.vertical {
  flex-direction: column;
}

.container-reports {
  max-width: 1110px;
  padding: 20px 30px 30px;
}

.instructions {
  font-size: 16px;
  color: #6b6b6b;
  padding-bottom: 14px;
}

.opportunity {
  display: flex;
  align-items: center;
  padding-bottom: 11px;

  .number-circle {
    background-color: $primaryColor;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    margin-right: 15px;
  }
}

.body-larger {
  font-size: 16px;
  line-height: 24px;
}

.call-out {
  font-weight: 700;
  color: $themeOrange;
  text-transform: uppercase;
}

@media screen and (max-width: $mobileScreenSmall) {
  .score-breakdown-row {
    padding-left: 0;
  }

  .opportunity {
    .number-circle {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      font-size: 24px;
    }

    .body-larger {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .section-header {
    h1 {
      font-size: 18px !important;
    }
  }
}
</style>
