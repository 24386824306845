<template>
  <div>
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="10">
        <v-card flat>
          <v-img
            class="site-logo"
            src="@/assets/img/HorizonEducation_Logo-p-1080.jpeg"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="5"
        class="d-none d-sm-block"
      >
        <v-card flat>
          <v-img src="@/assets/img/hero.png" />
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="5"
      >
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn
                class="classlink-sign-in-button"
                @click="authorizeClassLink"
              >
                <img
                  alt="login-class-link"
                  src="@/assets/img/LogInWithClassLink2x.png"
                >
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="auto"
              class="pt-0"
            >
              <v-btn
                class="clever-sign-in-button"
                @click="authorizeClever"
              >
                <img
                  alt="login-clever"
                  src="@/assets/img/LogInWithClever2x.png"
                >
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="auto"
              class="pt-0"
            >
              <div id="google_signin_button" />
            </v-col>
          </v-row>
          <v-divider class="mt-4" />
          <v-form class="pa-3">
            <v-row>
              <v-col cols="12">
                <label
                  class="label-default"
                  for="loginEmail"
                >
                  Email
                </label>
                <v-text-field
                  id="loginEmail"
                  v-model="loginParams.email"
                  :hide-details="!$v.loginParams.email.$error"
                  error-messages="Please enter a valid email"
                  solo
                  flat
                  class="input-default mt-1"
                  autocomplete="email"
                />
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <label
                  class="label-default"
                  for="loginPassword"
                >
                  Password
                </label>
                <v-text-field
                  id="loginPassword"
                  v-model="loginParams.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :hide-details="!$v.loginParams.password.$error"
                  :error-messages="$v.loginParams.password.$error ? 'Please enter a valid password' : null"
                  solo
                  flat
                  class="input-default mt-1"
                  :type="showPassword ? 'text' : 'password'"
                  autocomplete="current-password"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col
              cols="12"
              class="pa-6"
            >
              <template v-if="!skipRecaptcha()">
                <vue-recaptcha
                  ref="recaptcha"
                  sitekey="6LcJre0aAAAAAKobMw1zIGWpcUxxvy000wsW7_Yk"
                  style="width:100%"
                  @verify="recaptcha()"
                >
                  <v-btn
                    class="btn-default btn-default__primary"
                    dark
                    block
                  >
                    Sign In
                  </v-btn>
                </vue-recaptcha>
              </template>
              <template v-else>
                <v-btn
                  class="btn-default btn-default__primary"
                  dark
                  block
                  @click="login"
                >
                  Sign In
                </v-btn>
              </template>
            </v-col>
            <v-col
              cols="12"
              class="text-center"
            >
              <router-link :to="{ name: 'ForgotPassword' }">
                Forgot Password?
              </router-link>
            </v-col>
            <v-col
              cols="12"
              class="mt-3"
            >
              <div
                class="alert alert-success"
                role="alert"
              >
                <p>
                  If you are a user from School Year 22-23 and are looking for your historical data, please <strong
                    class="cursor-pointer"
                    @click="toOldPortal()"
                  >click here</strong> to login.
                </p>
              </div>
            </v-col>

            <!--Text to inform users that horizon is having technical issues-->
            <!--Uncomment/comment below column depending on the status of the app-->

            <!--            <v-col-->
            <!--              cols="12"-->
            <!--            >-->
            <!--              <div-->
            <!--                class="alert alert-warning"-->
            <!--                role="alert"-->
            <!--              >-->
            <!--                <h5>-->
            <!--                  <v-icon-->
            <!--                    size="20"-->
            <!--                    color="black"-->
            <!--                  >-->
            <!--                    mdi-alert-outline-->
            <!--                  </v-icon>-->

            <!--                  Horizon is experiencing technical difficulties right now and are working to resolve the issue as soon as possible.-->
            <!--                </h5>-->
            <!--              </div>-->
            <!--            </v-col>-->
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import {required, email} from "vuelidate/lib/validators"
import {createNamespacedHelpers} from 'vuex'
import {validationMixin} from "vuelidate/src";

const {mapActions, mapGetters} = createNamespacedHelpers('user')
const {mapState: mapAppConfigState} = createNamespacedHelpers('app_config')
export default {
  name: 'LoginView',
  components: {
    VueRecaptcha
  },
  mixins: [validationMixin],

  validations: {
    loginParams: {
      email: {required, email},
      password: {required}
    }
  },
  data() {
    return {
      showPassword: false,
      loginParams: {
        email: "",
        password: ""
      },
      email: '',
      password: '',
      recaptchad: false,
      bypassCaptchaId: '38f49872-4c9c-4118-9205-5b879ad0f9d2'
    }
  },
  computed: {
    ...mapAppConfigState({
      dashboard: app_config => app_config.dashboard
    })
  },
  created() {
    window.addEventListener('load', () => {
      window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        context: 'signin',
        ux_mode: 'popup',
        callback: this.onSignInSuccess,
        auto_prompt: 'false',
      })

      window.google.accounts.id.renderButton(
          document.getElementById('google_signin_button'),
          {
            type: 'standard',
            shape: 'rectangular',
            theme: 'filled_blue',
            text: 'signin_with',
            size: 'large',
            logo_alignment: 'left',
            width: 220,
          }
      )
    })
  },
  mounted() {
    let recaptcha_src = "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    let el = document.createElement('script')
    el.setAttribute('src', recaptcha_src)
    el.setAttribute('defer', true)
    el.setAttribute('async', true)
    document.head.appendChild(el)

    if (this.$route.query.state !== undefined) {
      let state = JSON.parse(this.$route.query.state)
      if (state.provider === "Clever") {
        this.loginCleverSession()
      }
      if (state.provider === "ClassLink") {
        this.loginClassLinkSession()
      }
    } else {
      if (document.referrer.includes('clever.com')
          && this.$route.query.code !== undefined) {
        this.authorizeClever()
      }
      if (this.$route.query.code !== undefined
          && this.$route.query.response_type !== undefined
          && this.$route.query.response_type == 'code') {
        this.authorizeClassLink()
      }
    }
  },
  methods: {
    ...mapActions([
      'createUserSession',
      'createGoogleUserSession',
      'createCleverAuthUrl',
      'createCleverUserSession',
      'createClassLinkAuthUrl',
      'createClassLinkUserSession'
    ]),
    ...mapGetters(['userFullName']),

    authorizeClever: function () {
      this.createCleverAuthUrl().then((url) => {
        window.location.href = url
      })
    },

    loginCleverSession: function () {
      this.createCleverUserSession({
        code: this.$route.query.code,
        portal_token: JSON.parse(this.$route.query.state).portal_token
      }).then(() => {
        this.$router.replace({'query': null})
        this.addNotification('success', `${this.salute()}, ${this.userFullName()}!`)
        this.$router.push(this.dashboard)
      }).catch(() => {
        this.addNotification('error', 'Clever authorization failed')
      })
    },

    authorizeClassLink: function () {
      this.createClassLinkAuthUrl().then((url) => {
        window.location.href = url
      })
    },

    loginClassLinkSession: function () {
      this.createClassLinkUserSession({
        code: this.$route.query.code,
        portal_token: JSON.parse(this.$route.query.state).portal_token
      }).then(() => {
        this.$router.replace({'query': null})
        this.addNotification('success', `${this.salute()}, ${this.userFullName()}!`)
        this.$router.push(this.dashboard)
      })
          .catch(() => {
            this.addNotification('error', 'ClassLink authorization failed')
          });
    },

    onSignInSuccess: async function (googleUser) {
      this.createGoogleUserSession({
        token: googleUser.credential,
        email: this.decodeJWT(googleUser.credential).email
      }).then(() => {
        this.addNotification('success', `${this.salute()}, ${this.userFullName()}!`)
        this.$router.push(this.dashboard)
      }).catch(() => {
        this.addNotification('error', `Can't login user with email ${this.decodeJWT(googleUser.credential).email}. Make sure a user with that email exists in Horizon Education.`)
      })
    },

    decodeJWT: function (token) {
      let base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')
      let data = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))

      return JSON.parse(data)
    },

    clearFields: function () {
      this.loginParams.email = ''
      this.loginParams.password = ''
      this.$v.$reset()
    },

    resetRecaptcha: function () {
      this.recaptchad = false
      this.$refs.recaptcha.reset()
    },

    recaptcha: async function () {
      this.recaptchad = true
      this.login()
    },

    skipRecaptcha() {
      return this.$route.name === 'loginBypassCaptcha' && this.$route.params.bypassCaptchaId === this.bypassCaptchaId
    },

    login: async function () {
      this.$v.$reset()
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      // Skip recaptcha if configured to do so
      if (this.skipRecaptcha()) {
        this.recaptchad = true;
      }
      
      if (!this.recaptchad) {
        this.addNotification('error', 'ReCaptcha error!')
        return
      }

      this.createUserSession({login: this.loginParams}).then(() => {
        this.clearFields()
        this.addNotification('success', `${this.salute()}, ${this.userFullName()}!`)
        this.$router.push(this.dashboard)
      }).catch(() => {
        this.addNotification('error', 'Login error, check your Username and Password')
        this.clearFields()
      }).finally(() => {
        this.resetRecaptcha()
      })
    },

    salute: function () {
      let salutes = ['Hi', 'Hello', 'Good Day', 'Welcome']
      return salutes[Math.floor(Math.random() * salutes.length)]
    },

    toOldPortal(){
      window.location.href = "https://portal2.horizoneducation.com";
    }
  }
}
</script>
<style scoped lang="scss">

.btn-default{
  font-size: 16px !important;
}

.classlink-sign-in-button {
  padding: 0 !important;
  height: 50px !important;
  border-radius: 7px;

  img {
    width: 220px;
    height: 50px;
  }

}

.clever-sign-in-button {
  padding: 0 !important;
  height: 50px !important;

  img {
    width: 220px;
    height: 50px;
  }
}

.google-icon {
  background: white;
  border: white;
  margin: 10px;
  padding: 3px;
  border-radius: 3px;
  width: 25px;
  height: 25px;
}

.signin-btn {
  height: 50px !important;
  font-size: 16px;
  font-weight: 600;
}

.google-signin-btn {
  width: 285px;
  justify-content: right;
}

.site-logo {
  width: 200px;
  height: 90px;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-warning {
  color: black;
  background-color: #ffc401;
  border-color: #ffc401;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

</style>
