<template>
  <v-dialog
    :value="dialog"
    persistent
    max-width="400px"
  >
    <v-card class="card-dialog">
      <app-header
        :title="title"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-row>
          <v-col cols="12">
            <h5>{{ confirmationMessage }}</h5>
          </v-col>
        </v-row>
        <v-row
          dense
          class="mt-6"
        >
          <v-col
            cols="12"
            sm="6"
            class="pr-sm-2"
          >
            <v-btn
              class="btn-default btn-default__secondary btn-default__small"
              block
              @click="onCloseDialog()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pl-sm-2"
          >
            <v-btn
              dark
              class="btn-default btn-default__danger btn-default__small"
              block
              @click="onConfirmDialog()"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";

export default {
  name: "DialogUpdateConfirmation",
  components: {AppHeader},
  props: {
    active: Boolean,
    title: {
      default: 'Update confirmation',
      type: String,
    },
    message: {
      default: null,
      type: String,
      required: false
    }
  },

  data() {
    return {
      dialog: false,
    }
  },

  watch: {
    active(value) {
      this.dialog = value
    },
  },

  computed: {
    confirmationMessage() {
      if (this.message == null || this.message == '') {
        return 'Are you sure you want to proceed with your updates?'
      } else {
        return this.message
      }
    }
  },

  methods: {
    onCloseDialog() {
      this.$emit('closeDialog', false)
    },

    onConfirmDialog() {
      this.$emit('closeDialog', true)
    },
  }
}
</script>

<style scoped>

</style>
