<template>
  <div>
    <v-container fluid>
      <v-row
        align="center"
        justify="space-between"
        class="mt-1"
        dense
      >
        <v-col
          cols="12"
          sm="auto"
        >
          <h3>
            Assessment Monitoring
          </h3>
        </v-col>

        <v-col
          cols="auto"
        >
          <v-btn
            v-if="!(user.role === 'Teacher' && user.school?.schoolAdminOnly)"
            icon
            aria-label="See more info"
            @click.stop="dialogHelp = true"
          >
            <v-icon
              size="22"
              color="#3d5d74"
            >
              mdi-help-circle-outline
            </v-icon>
          </v-btn>

          <v-dialog
            v-model="dialogHelp"
            max-width="500"
          >
            <v-card>
              <v-card-title class="text-h5">
                Actions
              </v-card-title>

              <v-card-text>
                <p>You can trigger the following operations on the assessments:</p>

                <p>
                  <v-icon
                    size="17"
                    color="#3d5d74"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                  <b>Reset Assessment</b>
                </p>
                <p>
                  This will erase all responses for the chosen student and assessment on the server, and allow the
                  student to re-take the assessment. The data cannot be recovered and the action cannot be undone.
                </p>

                <p>
                  <v-icon
                    size="17"
                    color="#3d5d74"
                  >
                    mdi-file-document-refresh-outline
                  </v-icon>
                  <b>Generate Report</b>
                </p>
                <p>This will recalculate the score report for the chosen assessment.</p>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogHelp = false"
                >
                  Okay
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row
            dense
            class="table-header-extension"
          >
            <v-col
              cols="12"
              sm="6"
              md="3"
              xl="3"
              class="d-flex align-center"
            >
              <v-text-field
                v-if="!tableLoading"
                v-model="tableSearch"
                placeholder="Search"
                prepend-inner-icon="mdi-magnify"
                hide-details
                clearable
                solo
                flat
                class="input-default input-default__table"
              />
            </v-col>
            <v-col
              cols="12"
              sm="auto"
              md="4"
              xl="3"
              class="d-flex align-center"
            >
              <v-select
                v-model="selectedAssessmentType"
                :items="getAvailableAssessmentTypes()"
                item-text="displayName"
                dense
                background-color="#FFFFFF"
                hide-details
                class="input-default input-default__table mt-0"
                placeholder="Assessment Type"
                :return-object="true"
                :disabled="tableLoading"
              />
            </v-col>
            <v-col
              cols="12"
              sm="auto"
              md="2"
              xl="3"
              class="d-flex align-center"
            >
              <v-select
                v-model="selectedAssessmentSet"
                :items="getAvailableAssessmentSets()"
                item-text="name"
                dense
                background-color="#FFFFFF"
                hide-details
                class="input-default input-default__table mt-0"
                placeholder="Assessment Set"
                :return-object="true"
                :disabled="tableLoading"
              />
            </v-col>
            <v-col
              cols="12"
              sm="auto"
              md="3"
              xl="3"
              class="d-flex align-center"
            >
              <v-autocomplete
                v-model="selectedSection"
                :items="sectionArray"
                item-text="displayName"
                item-value="id"
                menu-props="auto"
                dense
                background-color="#FFFFFF"
                hide-details
                class="input-default input-default__table mt-0"
                placeholder="Class"
                :loading="loadingSections"
                :disabled="tableLoading"
              />
            </v-col>
            <v-col
              v-if="noSelectedOptions"
              cols="12"
            >
              <h6 class="color-danger text-right">
                Please select a class and the assessment.
              </h6>
            </v-col>
          </v-row>

          <v-data-table
            v-model="selectedUsers"
            :height="getTableHeight()"
            show-select
            item-key="studentId"
            :headers="headers"
            :headers-length="headers.length + 1"
            :items="tableData"
            :options.sync="tableOptions"
            :server-items-length="totalStudents"
            :loading="tableLoading ? '#3d5d74' : false"
            loading-text="Loading Students"
            class="table-default table-default__select table-radius-top-none table-default__header-with-icon table-default__header-fixed"
            fixed-header
            :footer-props="{
              showCurrentPage: true,
              itemsPerPageOptions:[itemsPerPage],
              disableItemsPerPage: true
            }"
          >
            <template
              v-for="header in headers"
              #[`header.${header.value}`]
            >
              <div
                :key="header.text"
                class="d-flex flex-column justify-end h-100"
                :aria-label="`Header: ${header.text}`"
                tabindex="0"
              >
                <div v-if="header.liveMonitoring">
                  <v-btn
                    outlined
                    class="live-monitoring-button btn-table btn-table__text mb-1"
                    @click="loadLiveMonitoring(header.value, header.difficulty)"
                  >
                    Live Monitoring
                  </v-btn>
                </div>
                <div
                  v-if="header.addToClass"
                  @click.stop
                >
                  <v-btn
                    outlined
                    class="live-monitoring-button btn-table btn-table__text mb-1"
                    @click="loadAddToClass()"
                  >
                    Add to Class
                  </v-btn>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <span class="mr-3">
                    {{ header.text }}
                  </span>
                  <app-table-filter
                    v-if="header.value !== 'lastName'"
                    :filter-options="getFilterOptions(header?.value)"
                    :column="header?.value"
                    :loading="tableLoading"
                    @onFilterChange="onFilterChange"
                  />
                </div>
              </div>
            </template>

            <template #[`footer.page-text`]="items">
              <span class="font-weight-700">{{ items.pageStart }}</span> to
              <span class="font-weight-700">{{ items.pageStop }}</span> of
              <span class="font-weight-700">{{ items.itemsLength }}</span>
            </template>

            <template #[`header.actions`]>
              <div class="text-center">
                <v-btn
                  :disabled="tableLoading"
                  :loading="tableLoading"
                  color="white"
                  width="33"
                  min-width="33"
                  height="33"
                  fab
                  title="Reload Table"
                  @click="loadTable()"
                >
                  <v-icon
                    color="#3d5d74"
                    size="20"
                  >
                    mdi-cached
                  </v-icon>
                </v-btn>
              </div>
            </template>

            <template
              v-if="!isMobile"
              #[`item.lastName`]="{ item }"
            >
              <td>
                <span class="mr-3">
                  {{ item.firstName }} {{ item.lastName }}
                </span>
                <span
                  v-if="item.untimed"
                  class="untimed-container"
                >
                  Untimed
                </span>
              </td>
            </template>

            <!--Sat Table Data-->
            <template
              v-if="!isMobile"
              #[`item.readingWritingP1`]="{ item }"
            >
              <td>
                <div class="d-flex align-center">
                  <span
                    class="mr-2 font-weight-500"
                    :class="(itemStatus(item.readingWritingP1?.status))"
                  >
                    {{ item.readingWritingP1?.status }}
                  </span>
                  <v-btn
                    v-if="canResetAssessment(item.readingWritingP1)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Reset Assessment"
                    @click="confirmResetAssessment(item.readingWritingP1)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </div>
              </td>
            </template>
            <template
              v-if="!isMobile"
              #[`item.readingWritingP2`]="{ item }"
            >
              <td>
                <div class="d-flex align-center">
                  <span
                    class="mr-2 font-weight-500"
                    :class="(itemStatus(item.readingWritingP2?.status))"
                  >
                    {{ item.readingWritingP2?.status }}
                  </span>
                  <v-btn
                    v-if="canResetAssessment(item.readingWritingP2)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Reset Assessment"
                    @click="confirmResetAssessment(item.readingWritingP2)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="canGenerateReport(item.readingWritingP2)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Generate Report"
                    @click="generateReport(item.readingWritingP2)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-file-document-refresh-outline
                    </v-icon>
                  </v-btn>
                </div>
              </td>
            </template>
            <template
              v-if="!isMobile"
              #[`item.mathP1`]="{ item }"
            >
              <td>
                <div class="d-flex align-center">
                  <span
                    class="mr-2 font-weight-500"
                    :class="(itemStatus(item.mathP1?.status))"
                  >
                    {{ item.mathP1?.status }}
                  </span>
                  <v-btn
                    v-if="canResetAssessment(item.mathP1)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Reset Assessment"
                    @click="confirmResetAssessment(item.mathP1)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </div>
              </td>
            </template>
            <template
              v-if="!isMobile"
              #[`item.mathP2`]="{ item }"
            >
              <td>
                <div class="d-flex align-center">
                  <span
                    class="mr-2 font-weight-500"
                    :class="(itemStatus(item.mathP2?.status))"
                  >
                    {{ item.mathP2?.status }}
                  </span>
                  <v-btn
                    v-if="canResetAssessment(item.mathP2)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Reset Assessment"
                    @click="confirmResetAssessment(item.mathP2)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="canGenerateReport(item.mathP2)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Generate Report"
                    @click="generateReport(item.mathP2)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-file-document-refresh-outline
                    </v-icon>
                  </v-btn>
                </div>
              </td>
            </template>


            <!--ACT Table Data-->
            <template
              v-if="!isMobile"
              #[`item.math`]="{ item }"
            >
              <td>
                <div class="d-flex align-center">
                  <span
                    class="mr-2 font-weight-500"
                    :class="(itemStatus(item.math?.status))"
                  >
                    {{ item.math?.status }}
                  </span>
                  <v-btn
                    v-if="canResetAssessment(item.math)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Reset Assessment"
                    @click="confirmResetAssessment(item.math)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="canGenerateReport(item.math)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Generate Report"
                    @click="generateReport(item.math)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-file-document-refresh-outline
                    </v-icon>
                  </v-btn>
                </div>
              </td>
            </template>
            <template
              v-if="!isMobile"
              #[`item.science`]="{ item }"
            >
              <td>
                <div class="d-flex align-center">
                  <span
                    class="mr-2 font-weight-500"
                    :class="(itemStatus(item.science?.status))"
                  >
                    {{ item.science?.status }}
                  </span>
                  <v-btn
                    v-if="canResetAssessment(item.science)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Reset Assessment"
                    @click="confirmResetAssessment(item.science)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="canGenerateReport(item.science)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Generate Report"
                    @click="generateReport(item.science)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-file-document-refresh-outline
                    </v-icon>
                  </v-btn>
                </div>
              </td>
            </template>
            <template
              v-if="!isMobile"
              #[`item.reading`]="{ item }"
            >
              <td>
                <div class="d-flex align-center">
                  <span
                    class="mr-2 font-weight-500"
                    :class="(itemStatus(item.reading?.status))"
                  >
                    {{ item.reading?.status }}
                  </span>
                  <v-btn
                    v-if="canResetAssessment(item.reading)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Reset Assessment"
                    @click="confirmResetAssessment(item.reading)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="canGenerateReport(item.reading)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Generate Report"
                    @click="generateReport(item.reading)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-file-document-refresh-outline
                    </v-icon>
                  </v-btn>
                </div>
              </td>
            </template>
            <template
              v-if="!isMobile"
              #[`item.english`]="{ item }"
            >
              <td>
                <div class="d-flex align-center">
                  <span
                    class="mr-2 font-weight-500"
                    :class="(itemStatus(item.english?.status))"
                  >
                    {{ item.english?.status }}
                  </span>
                  <v-btn
                    v-if="canResetAssessment(item.english)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Reset Assessment"
                    @click="confirmResetAssessment(item.english)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="canGenerateReport(item.english)"
                    class="btn-table btn-table__icon"
                    outlined
                    icon
                    title="Generate Report"
                    @click="generateReport(item.english)"
                  >
                    <v-icon
                      size="18"
                      color="#3d5d74"
                    >
                      mdi-file-document-refresh-outline
                    </v-icon>
                  </v-btn>
                </div>
              </td>
            </template>
            <template
              v-if="!isMobile"
              #[`item.actions`]="{ item }"
            >
              <td class="text-center">
                <v-btn
                  v-if="canViewCompositeReport(item)"
                  class="btn-table btn-table__text"
                  outlined
                  @click="viewCompositeReport(item)"
                >
                  View Score Report
                </v-btn>
              </td>
            </template>

            <template
              v-if="isMobile"
              #item="{ item, isSelected, select }"
            >
              <tr>
                <td>
                  <ul class="flex-content">
                    <li
                      class="flex-item"
                      data-label="Selected"
                    >
                      <v-simple-checkbox
                        :value="isSelected"
                        @click="select(!isSelected)"
                      />
                    </li>
                    <li
                      class="flex-item"
                      data-label="Name"
                    >
                      {{ `${item.firstName} ${item.lastName}` }}
                      <span
                        v-if="item.untimed"
                        class="untimed-container"
                      >Untimed</span>
                    </li>
                    <li
                      class="flex-item"
                      data-label="Grade"
                    >
                      {{ item.gradeLevel }}
                    </li>

                    <!--ACT Table Data-->
                    <li
                      v-if="courseType === 'ACT'"
                      class="flex-item"
                      data-label="English"
                      :class="(itemStatus(item.english?.status))"
                    >
                      {{ item.english?.status }}
                      <div class="d-flex">
                        <v-btn
                          v-if="canResetAssessment(item.english)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Reset Assessment"
                          @click="confirmResetAssessment(item.english)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="canGenerateReport(item.english)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Generate Report"
                          @click="generateReport(item.english)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-file-document-refresh-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </li>
                    <li
                      v-if="courseType === 'ACT'"
                      class="flex-item"
                      data-label="Math"
                      :class="(itemStatus(item.math?.status))"
                    >
                      {{ item.math?.status }}
                      <div class="d-flex">
                        <v-btn
                          v-if="canResetAssessment(item.math)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Reset Assessment"
                          @click="confirmResetAssessment(item.math)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="canGenerateReport(item.math)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Generate Report"
                          @click="generateReport(item.math)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-file-document-refresh-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </li>
                    <li
                      v-if="courseType === 'ACT'"
                      class="flex-item"
                      data-label="Reading"
                      :class="(itemStatus(item.reading?.status))"
                    >
                      {{ item.reading?.status }}
                      <div class="d-flex">
                        <v-btn
                          v-if="canResetAssessment(item.reading)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Reset Assessment"
                          @click="confirmResetAssessment(item.reading)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="canGenerateReport(item.reading)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Generate Report"
                          @click="generateReport(item.reading)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-file-document-refresh-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </li>
                    <li
                      v-if="courseType === 'ACT'"
                      class="flex-item"
                      data-label="Science"
                      :class="(itemStatus(item.science?.status))"
                    >
                      {{ item.science?.status }}
                      <div class="d-flex">
                        <v-btn
                          v-if="canResetAssessment(item.science)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Reset Assessment"
                          @click="confirmResetAssessment(item.science)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="canGenerateReport(item.science)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Generate Report"
                          @click="generateReport(item.science)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-file-document-refresh-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </li>

                    <!--Sat Table Data-->
                    <li
                      v-if="courseType === 'SAT'"
                      class="flex-item"
                      data-label="Reading & Writing #1"
                      :class="(itemStatus(item.readingWritingP1?.status))"
                    >
                      {{ item.readingWritingP1?.status }}
                      <div>
                        <v-btn
                          v-if="canResetAssessment(item.readingWritingP1)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Reset Assessment"
                          @click="confirmResetAssessment(item.readingWritingP1)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </li>
                    <li
                      v-if="courseType === 'SAT'"
                      class="flex-item"
                      data-label="Reading & Writing #2"
                      :class="(itemStatus(item.readingWritingP2?.status))"
                    >
                      {{ item.readingWritingP2?.status }}
                      <div class="d-flex">
                        <v-btn
                          v-if="canResetAssessment(item.readingWritingP2)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Reset Assessment"
                          @click="confirmResetAssessment(item.readingWritingP2)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="canGenerateReport(item.readingWritingP2)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Generate Report"
                          @click="generateReport(item.readingWritingP2)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-file-document-refresh-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </li>
                    <li
                      v-if="courseType === 'SAT'"
                      class="flex-item"
                      data-label="Math #1"
                      :class="(itemStatus(item.mathP1?.status))"
                    >
                      {{ item.mathP1?.status }}
                      <div>
                        <v-btn
                          v-if="canResetAssessment(item.mathP1)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Reset Assessment"
                          @click="confirmResetAssessment(item.mathP1)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </li>
                    <li
                      v-if="courseType === 'SAT'"
                      class="flex-item"
                      data-label="Math #2"
                      :class="(itemStatus(item.mathP2?.status))"
                    >
                      {{ item.mathP2?.status }}
                      <div class="d-flex">
                        <v-btn
                          v-if="canResetAssessment(item.mathP2)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Reset Assessment"
                          @click="confirmResetAssessment(item.mathP2)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="canGenerateReport(item.mathP2)"
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          title="Generate Report"
                          @click="generateReport(item.mathP2)"
                        >
                          <v-icon
                            size="18"
                            color="#3d5d74"
                          >
                            mdi-file-document-refresh-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </li>
                    <v-col
                      cols="12"
                      class="d-flex"
                    >
                      <v-btn
                        v-if="canViewCompositeReport(item)"
                        class="btn-table btn-table__text"
                        outlined
                        @click="viewCompositeReport(item)"
                      >
                        View Score Report
                      </v-btn>
                    </v-col>
                  </ul>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <dialog-delete-confirmation
        :active="dialogResetConfirmation"
        :message="`Resetting the assessment will delete the student's responses for this exam from the server. The data cannot be recovered and this action cannot be undone.`"
        @confirmDeletion="resetAssessment()"
      />

      <dialog-diagnostic
        :active="dialogDiagnostic"
        :body-text-prop="diagnosticMessage"
        @confirmDialog="dialogDiagnostic = false"
      />

      <dialog-add-to-class
        :active="dialogAddToClass"
        :student-ids="studentIds"
        :school-id="schoolId"
        @closeDialog="onCloseDialogAddToClass()"
        @closeDialogUpdate="onCloseDialogAddToClass(true)"
      />
    </v-container>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapState} = createNamespacedHelpers('app_config')
const {mapState: mapUserState} = createNamespacedHelpers('user')
const {mapGetters: mapSectionGetters} = createNamespacedHelpers('staffSections')
const {mapGetters: mapAssessmentGetters} = createNamespacedHelpers('assessments')

import {StaffAssessmentApi, StaffSchoolApi, StaffStudentAssessmentApi} from "@/api"

import DialogDeleteConfirmation from '@/components/shared/DialogDeleteConfirmation.vue'
import DialogDiagnostic from '@/components/shared/DialogDiagnostic.vue'
import DialogAddToClass from "@/components/shared/DialogAddToClass.vue";
import AppTableFilter from "@/components/shared/AppTableFilter.vue";
import debounce from 'lodash/debounce'
import cachedMixin from '@/mixins/cachedMixin'

export default {
  name: "StaffAssessmentsMonitoring",

  components: {
    AppTableFilter,
    DialogDeleteConfirmation,
    DialogDiagnostic,
    DialogAddToClass
  },
  
  mixins: [
    cachedMixin
  ],

  props: {
    sectionId: {
      required: false
    },

    assessmentSetId: {
      required: false
    },

    assessmentTypeId: {
      required: false
    },
  },

  data() {
    return {
      pageLoadedOnce: false,
      tableLoading: false,
      onTableSearch: false,
      selectedSection: null,
      selectedAssessmentSet: null,
      selectedAssessmentType: null,
      tableSearch: '',
      tableData: [],
      headers: [],
      dialogResetConfirmation: false,
      assessmentToReset: null,
      dialogHelp: false,
      generateReportErrorCount: 0,
      dialogDiagnostic: false,
      diagnosticMessage: "",
      itemsPerPage: 25,
      currentPage: 1,
      totalStudents: 0,
      sectionArray: [],
      loadingSections: false,
      selectedUsers: [],
      noSelectedUsers: false,
      dialogAddToClass: false,
      noSelectedOptions: false,
      studentIds: [],
      tableHeight: 'calc(90vh - 450px)',
      tableActiveFilters: {},
      gradeFilterOptions: [8, 9, 10, 11, 12],
      statusFilterOptions: ['Not Scheduled', 'Scheduled', 'Not Started', 'In Progress', 'Completed'],
      tableOptions: {
        itemsPerPage: 25,
        page: 1,
        sortBy: ['lastName'],
        sortDesc: [false]
      },
    }
  },

  computed: {
    ...mapUserState(["user"]),
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),

    courseType() {
      return this.user.school.courseType
    },

    schoolId() {
      return this.user.school.id
    },
  },

  watch: {
    tableSearch: {
      handler: debounce(function () {
        if (this.canLoadMonitoringView()) {
          this.onTableSearch = true
          // this.tableOptions.page = 1
          this.loadTable()
        }
      }, 500),
    },

    tableOptions: {
      handler() {
        if (!this.onTableSearch && this.pageLoadedOnce) {
          this.loadTable()
        }
      },
      deep: true,
    },

    assessmentSetId: {
      immediate: true,
      handler() {
        this.selectedAssessmentSet = this.getAvailableAssessmentSets().find(assessmentSet => assessmentSet.id == this.assessmentSetId)
      }
    },

    assessmentTypeId: {
      immediate: true,
      handler() {
        this.selectedAssessmentType = this.getAvailableAssessmentTypes().find(assessmentType => assessmentType.id == this.assessmentTypeId)
      }
    },

    selectedSection() {
      if (this.canLoadMonitoringView()) {
        this.updatePageUrl()
        this.loadTable()
      }
    },

    selectedAssessmentSet() {
      if (this.canLoadMonitoringView()) {
        this.updatePageUrl()
        this.loadTable()
      }
    },

    selectedAssessmentType() {
      if (this.canLoadMonitoringView()) {
        this.updatePageUrl()
        this.loadTable()
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    this.savePageData({ 
      page: this.$options.name, 
      data: { 
        tableData: this.tableData,
        totalStudents: this.totalStudents,
        tableSearch: this.tableSearch,
        tableOptions: this.tableOptions,
      }
    })
    next()
  },

  mounted() {
    if (!this.cachedDataLoaded && this.shouldTryCachedData && this.hasCachedData) {
      this.tableLoading = true
      this.totalStudents = this.cachedData.totalStudents
      this.tableSearch = this.cachedData.tableSearch
      this.tableOptions = this.cachedData.tableOptions
      this.tableLoading = false
      this.cachedDataLoaded = true
    }
    this.initSetup()
  },

  methods: {
    ...mapSectionGetters(["getAllSections"]),
    ...mapAssessmentGetters(["getAvailableAssessmentTypes", "getAvailableAssessmentSets"]),

    async initSetup() {

      this.updateHeaders()

      await this.loadSections()

      // Set section id
      if (this.sectionId) {
        if (this.user.role === 'Teacher') {
          this.selectedSection = this.sectionArray.find(section => section.id == this.sectionId)?.id
        } else {
          this.selectedSection = Number(this.sectionId)
        }
      }
    },

    resetSelectedUsers() {
      this.selectedUsers = []
    },

    updateHeaders() {
      let assessmentType = this.selectedAssessmentType

      if (assessmentType != undefined && assessmentType != null && assessmentType.assessmentType === "SAT") {
        this.headers = [
          {
            text: 'Name',
            value: 'lastName',
            align: 'left',
            sortable: true,
            addToClass: true,
          },
          {
            text: 'Grade',
            value: 'gradeLevel',
            align: 'left',
            sortable: true,
          },
          {
            text: 'Reading & Writing #1',
            value: 'readingWritingP1',
            align: 'left',
            sortable: true,
            liveMonitoring: true,
            difficulty: 'default',
          },
          {
            text: 'Reading & Writing #2',
            value: 'readingWritingP2',
            align: 'left',
            sortable: true,
            liveMonitoring: true,
            difficulty: 'easy',
          },
          {
            text: 'Math #1',
            value: 'mathP1',
            align: 'left',
            sortable: true,
            liveMonitoring: true,
            difficulty: 'default',
          },
          {
            text: 'Math #2',
            value: 'mathP2',
            align: 'left',
            sortable: true,
            liveMonitoring: true,
            difficulty: 'easy',
          },
          {
            text: '',
            value: 'actions',
            align: 'left',
            sortable: false,
            width: 180,
          }
        ]
      } else {
        this.headers = [
          {
            text: 'Name',
            value: 'lastName',
            align: 'left',
            sortable: true,
          },
          {
            text: 'Grade',
            value: 'gradeLevel',
            align: 'left',
            sortable: true,
          },
          {
            text: 'English',
            value: 'english',
            align: 'left',
            sortable: true,
            liveMonitoring: true,
            difficulty: 'default',
          },
          {
            text: 'Math',
            value: 'math',
            align: 'left',
            sortable: true,
            liveMonitoring: true,
            difficulty: 'default',
          },
          {
            text: 'Reading',
            value: 'reading',
            align: 'left',
            sortable: true,
            liveMonitoring: true,
            difficulty: 'default',
          },
          {
            text: 'Science',
            value: 'science',
            align: 'left',
            sortable: true,
            liveMonitoring: true,
            difficulty: 'default',
          },
          {
            text: '',
            value: 'actions',
            align: 'left',
            sortable: false,
            width: 180,
          }
        ]
      }

    },

    async loadTable() {
      try {
        if(!this.canLoadMonitoringView()) {
          this.noSelectedOptionsDisplay()
          return
        }

        const searchText = this.tableSearch === '' ? undefined : this.tableSearch

        let {sortBy, sortDesc, page} = this.tableOptions
        let sortByParam = undefined
        let order = undefined
        if (sortBy[0]) {
          if (sortDesc[0]) {
            order = 'desc'
          } else {
            order = 'asc'
          }

          if (!['lastName', 'gradeLevel'].includes(sortBy[0])) {
            sortByParam = sortBy[0] + '.status'
          } else {
            sortByParam = sortBy[0]
          }
        }


        this.tableLoading = true
        const response = await StaffAssessmentApi.monitoring(
            this.selectedSection,
            this.selectedAssessmentSet?.id,
            this.selectedAssessmentType?.id,
            page,
            this.itemsPerPage,
            sortByParam,
            order,
            searchText,
            this.tableActiveFilters)
        this.updateHeaders()
        this.tableData = response.assessments
        this.totalStudents = response.meta?.totalCount
      } catch {
        this.addNotification('error', 'Failed to load Student List')
      } finally {
        this.pageLoadedOnce = true
        this.tableLoading = false
        this.onTableSearch = false
      }
    },

    noSelectedOptionsDisplay() {
      this.noSelectedOptions = true
      setTimeout(() => {
        this.noSelectedOptions = false
      }, 5000)
    },

    getFilterOptions(column) {
      if(column === 'gradeLevel') return this.gradeFilterOptions

      return this.statusFilterOptions
    },

    onFilterChange(column, filterArray) {
      this.tableActiveFilters[column] = filterArray
      this.loadTable()
    },

    async loadSections() {
      try {
        this.loadingSections = true
        if (this.user.role === 'Teacher Admin' || this.user.role === 'Teacher' || this.user.role === "District Admin") {
          const response = await StaffSchoolApi.getAllClassesMinimal()
          this.sectionArray = response.sections
        } else {
          this.sectionArray = this.getAllSections()
        }
        this.sectionArray.unshift({id: 0, displayName: 'Entire School'})
      } catch {
        this.addNotification('error', 'Failed to load class list')
      } finally {
        this.loadingSections = false
      }
    },

    canLoadMonitoringView() {
      return (this.selectedSection != null && this.selectedAssessmentSet != null && this.selectedAssessmentType != null)
    },

    canResetAssessment(assessment) {
      if (this.user.role === 'Teacher' && this.user.school?.schoolAdminOnly) {
        return false
      }

      if (assessment?.status === "In Progress" || assessment?.status === "Completed") {
        return true
      } else {
        return false
      }
    },

    confirmResetAssessment(assessment) {
      this.assessmentToReset = assessment
      this.dialogResetConfirmation = false
      this.$nextTick(() => {
        this.dialogResetConfirmation = true
      })
    },

    async resetAssessment() {
      let assessment = this.assessmentToReset
      try {
        this.tableLoading = true
        const response = await StaffStudentAssessmentApi.reset({
          sectionId: this.selectedSection,
          assessmentId: assessment.studentAssessmentId
        })
        this.addNotification('success', response.message)
        this.dialogResetConfirmation = false
        this.loadTable()
      } catch {
        this.addNotification('error', "Something went wrong. Please try again.")
      } finally {
        this.tableLoading = false
      }
    },

    canViewCompositeReport(assessments) {
      let categories = []
      if (this.selectedAssessmentType.assessmentType === "SAT") {
        categories = ["readingWritingP1", "readingWritingP2", "mathP1", "mathP2"]
      } else {
        categories = ["reading", "math", "english", "science"]
      }

      return categories.filter(cat => assessments[cat]?.status === "Completed").length > 0
    },

    viewCompositeReport(assessment) {
      this.$router.push({
        name: "StaffReportComposite",
        params: {
          studentId: assessment.studentId,
          sectionId: this.selectedSection,
          assessmentSetId: this.selectedAssessmentSet.id,
          assessmentTypeId: this.selectedAssessmentType.id,
        }
      })
    },

    canGenerateReport(assessment) {
      if (this.user.role === 'Teacher' && this.user.school?.schoolAdminOnly) {
        return false
      }

      if (assessment?.completedInHorizon) {
        return true
      } else {
        return false
      }
    },

    async generateReport(assessment) {
      try {
        this.tableLoading = true
        const response = await StaffStudentAssessmentApi.generateReport({
          sectionId: this.selectedSection,
          assessmentId: assessment.studentAssessmentId
        })
        this.addNotification('success', response.message)

        this.loadTable()
        this.generateReportErrorCount = 0
      } catch (e) {
        this.generateReportErrorCount += 1
        this.addNotification('error', "Something went wrong. Please try again.")

        if (this.generateReportErrorCount >= 3) {
          this.dialogDiagnostic = true
          this.diagnosticMessage = `ID: ${assessment.studentAssessmentId}\n`
          if (e.response.data.backtrace !== null) {
            this.diagnosticMessage += `${e.response.data.message}\n`
            this.diagnosticMessage += e.response.data.backtrace
          } else {
            this.diagnosticMessage += `${e.response.data}\n`
          }
        }
      } finally {
        this.tableLoading = false
      }
    },

    itemStatus(status) {
      if (status === 'Completed') return 'text-success'
      if (status === 'In Progress') return 'text-warning'
    },

    loadLiveMonitoring(subjectName, difficulty) {
      this.$router.push({
        name: 'StaffAssessmentLiveMonitoring',
        params: {
          assessmentTypeId: this.selectedAssessmentType.id,
          sectionId: this.selectedSection,
          assessmentSetId: this.selectedAssessmentSet.id,
          subjectName: subjectName,
          difficulty: difficulty
        }
      })
    },

    loadAddToClass() {
      if (this.selectedUsers.length === 0) {
        this.noSelectedUsersDisplay()
        return
      }
      this.noSelectedUsers = false
      this.studentIds = this.selectedUsers.map(obj => obj.studentId)
      this.dialogAddToClass = true
    },

    onCloseDialogAddToClass() {
      this.dialogAddToClass = false
    },

    noSelectedUsersDisplay() {
      this.noSelectedUsers = true
      this.addNotification('warning', 'Please select at least one user!')
      setTimeout(() => {
        this.noSelectedUsers = false
      }, 5000)
    },

    updatePageUrl() {
      // mainly to retain history when we navigate back to the page
      let url = this.$router.resolve({
        name: "StaffAssessmentMonitoring",
        params: {
          assessmentTypeId: this.selectedAssessmentType.id,
          sectionId: this.selectedSection,
          assessmentSetId: this.selectedAssessmentSet.id
        }
      }).href
      history.replaceState(null, '', url)
    },

    getTableHeight() {
      if (this.tableData.length < 6) return undefined

      if (this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 900) return '400px'

      return this.tableHeight
    },

  },
}
</script>

<style scoped>


.input-default {
  font-size: 13px;
}

.btn-table__icon {
  margin: 0 4px;
}

.live-monitoring-header {
  background-color: rgb(234, 236, 240);
  margin-bottom: -38px;
}

.live-monitoring-container {
  margin-left: -15px;
  margin-top: -18px;
}

.live-monitoring-button {
  width: 160px;
}

.live-monitoring-button-container {
  margin-right: 20px;
}

.live-monitoring-empty-button {
  flex-grow: 0 !important;
}

.live-monitoring-empty {
  width: 355px;
  max-width: 355px;
}


</style>