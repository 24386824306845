import { render, staticRenderFns } from "./StudentReportComposite.vue?vue&type=template&id=0a8b418d&scoped=true&"
import script from "./StudentReportComposite.vue?vue&type=script&lang=js&"
export * from "./StudentReportComposite.vue?vue&type=script&lang=js&"
import style0 from "./StudentReportComposite.vue?vue&type=style&index=0&id=0a8b418d&prod&lang=scss&scoped=true&"
import style1 from "./StudentReportComposite.vue?vue&type=style&index=1&id=0a8b418d&prod&lang=scss&"
import style2 from "./StudentReportComposite.vue?vue&type=style&index=2&id=0a8b418d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a8b418d",
  null
  
)

export default component.exports