import {createNamespacedHelpers} from "vuex";
const {mapActions: mapPageActions, mapGetters: mapPageGetters} = createNamespacedHelpers('pages')

export default {
  data() {
    return {
      shouldTryCachedData: false,
      cachedDataLoaded: false
    }
  },

  computed: {
    cachedData() {
      return this.cachedPages()[this.$options.name].data
    },

    hasCachedData() {
      let cachedData = this.cachedPages()[this.$options.name]
      if (cachedData) {
        // if stored data is less than 2 minutes old, we can load it 
        return (Date.now() - cachedData.timestamp)/1000 < 120
      } else {
        return false
      }
    }
  },

  beforeRouteEnter(from, to, next) {
    // detect a back button event
    if (window.event && window.event.type == "popstate") {
      next( vm => {
        vm.shouldTryCachedData = true
      })
    }
    else {
      next()
    }
  },

  methods: {
    ...mapPageActions([
      'savePageData'
    ]),
    ...mapPageGetters([
      'cachedPages'
    ]),
  }
}
