import User from './modules/user'
import AppConfig from './modules/app_config'
import StaffSections from './modules/staff_sections'
import Assessments from './modules/assessments'
import Terms from './modules/terms'
import CachedPages from './modules/cached_pages'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';

const localStore = new VuexPersist({
  storage: window.localStorage,
});

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [localStore.plugin],
  modules: {
    user: User,
    app_config: AppConfig,
    staffSections: StaffSections,
    assessments: Assessments,
    terms: Terms,
    pages: CachedPages
  }
})
