<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
  >
    <v-card class="card-dialog">
      <v-overlay :value="transferLoading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        title="Transfer Schools"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-row>
          <v-col>
            <div class="label-default-container">
            <label
                class="label-default"
                for="selectedSchool"
              >
                Select New School
              </label>
            </div>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="selectedSchool"
              :items="schools"
              item-text="name"
              item-value="id"
              class="input-default"
              clearable
              dense
              flat
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              v-model="retainSections"
              label="Retain classes"
              color="#496e88"
              class="switch-form ml-1 mr-6 pt-1"
              inset
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <div v-if="retainSections" class="text-body-2 text-secondary">
              The student(s) will <b>remain enrolled</b> in classes at this school. They will be enrolled in default grade-level classes in the new school. Assessment data will be unchanged.
            </div>
            <div v-else class="text-body-2 text-secondary">
              The student(s) will be <b>removed</b> from all classes at this school. They will be enrolled in default grade-level classes in the new school. Assessment data will be unchanged.
            </div>
          </v-col>
        </v-row>

        <v-row
          dense
          class="mt-6"
        >
          <v-col
            cols="12"
            sm="6"
            class="pr-sm-2"
          >
            <v-btn
              class="btn-default btn-default__secondary btn-default__small"
              block
              @click="onCloseDialog()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pl-sm-2"
          >
            <v-btn
              dark
              class="btn-default btn-default__small"
              block
              @click="onConfirmDialog()"
            >
              Transfer
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";

import { AdminDistrictApi, AdminSchoolApi } from "@/api";

export default {
  name: "DialogStudentTransfer",
  components: {AppHeader},
  props: {
    active: Boolean,
    transferLoading: Boolean,
    schoolId: {
      type: [String, Number],
      required: true
    }
  },

  data() {
    return {
      dialog: false,
      schools: [],
      selectedSchool: null,
      retainSections: false,
      schoolDistrictId: null
    }
  },

  watch: {
    active(value) {
      this.dialog = value
      if (value) {
        this.loadSchools()
      }
    }
  },

  methods: {

    async loadSchools() {
      // get the school district id from the school id
      const schoolResponse = await AdminSchoolApi.get(this.schoolId)
      this.schoolDistrictId = schoolResponse.school.schoolDistrict.id

      const response = await AdminDistrictApi.getSchools(this.schoolDistrictId);
      this.schools = response.schools.filter(s => s.id != this.schoolId);
    },

    onCloseDialog() {
      this.dialog = false
    },

    onConfirmDialog() {
      this.$emit('confirmTransfer', this.selectedSchool, this.retainSections)
    },
  }
}
</script>

<style scoped>

</style>