function initialState () {
  return {
    data: {}
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    cachedPages: (state) => {
      return state.data
    },
    masqPageState: (state) => {
      return state.masqPageState
    }
  },
  mutations: {
    resetState: (state) => {
      const s = initialState()
      Object.keys(s).forEach( key => {
        state[key] = s[key]
      })
      sessionStorage.clear();
    },
    addPage: (state, {page, data}) => {
      state.data[page] = {
        timestamp: Date.now(),
        data: data
      }
    },
    saveMasqPageState: (state, {page, path, data}) => {
      state.masqPageState = {
        page: page,
        path: path,
        data: data
      }
    },
    resetMasqueradeData: (state) => {
      state.masqPageState = null
    }
  },
  actions: {
    savePageData({commit}, {page, data}) {
      commit('addPage', {page, data})
    },
    resetCache({commit}) {
      commit('resetState')
    },
    savePageBeforeMasquerade({commit}, {page, path, data}) {
      commit('saveMasqPageState', {page, path, data})
    },
    resetMasqueradeData({commit}) {
      commit('resetMasqueradeData')
    }
  }
}